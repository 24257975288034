import React from 'react';

import { useDispatch } from 'react-redux';

import clsx from 'clsx';
import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { BrowserStorage, formatCurrency } from 'atc-js';

import { Button, Glyphicon, Image, Text } from 'reaxl';
import { useAnalytics } from 'reaxl-analytics';
import { EmailCTA } from 'reaxl-email';
import { OwnerPhoneNumber } from 'reaxl-molecules';

import { srpActiveEmailListingDuck, srpActiveInteractionDuck } from '@/ducks/srp';

import useVdpNavigation from '@/hooks/useVdpNavigation';

function SrpListingAlert({
    alertTitle,
    isDismissible = true,
    displayEmailCta = true,
    displayMultipleImages = false,
    handleCloseAlert = () => { },
    listing = {},
    listingType,
    isStillInterestedAlert = false,
    urgencyDriverLabel,
    analyticsContext,
    textSize,
    ...rest
}) {

    const dispatch = useDispatch();
    const device = useDevice();
    const lessThanSmall = _get(device, 'lessThan.sm', false);

    const navigateToVdp = useVdpNavigation();
    const { sendClick } = useAnalytics();

    const cbhVehiclesCache = new BrowserStorage('VIEWED_CBH_VEHICLES', { persist: true });

    const stillInterestedImage = listing?.images?.sources?.[listing?.images?.primary];

    const vehiclePricingDetail = listing?.pricingDetail;
    const vehiclePrice = vehiclePricingDetail?.incentive || vehiclePricingDetail?.salePrice || vehiclePricingDetail?.msrp;

    const assignImages = () => {
        let imageArray = [];

        if (!displayMultipleImages) {
            // limit to 1 image
            imageArray.push(stillInterestedImage);
        }

        // iterate through premimum spotlight images, up to 4
        const filteredImages = listing?.images?.sources?.filter((image) => image.src !== undefined);
        if (filteredImages?.length && displayMultipleImages) {
            imageArray = filteredImages.slice(0, 4);
        }

        return imageArray;
    };

    const listingImages = assignImages();

    const ownerPhoneDetails = listing?.phone;

    const viewedVehicleIds = cbhVehiclesCache.getItem()?.listingIds || [];

    const analyticsData = {
        clickType: urgencyDriverLabel,
        inventoryId: listing.id,
        ownerId: listing?.owner?.id,
        pixallData: {
            listingType: urgencyDriverLabel,
            pixallContext: analyticsContext,
        },
    };

    const handleDismissAlert = () => {
        handleCloseAlert(false);
        cbhVehiclesCache.setItem({ listingIds: [...viewedVehicleIds, listing?.id] });
    };

    const handleVdpLinkClick = (event) => {
        // use this to differentiate between spotlight top placement, spotlight carousel, and still interested alert
        const analyticsLabel = (listingType === 'interested' && !isStillInterestedAlert) ? 'interested_spotlight' : urgencyDriverLabel;

        sendClick('inventoryClick', event, {
            inventoryId: listing.id,
            par: analyticsLabel,
        });

        navigateToVdp({
            listingId: listing.id,
            clickType: urgencyDriverLabel,
        });

        if (isDismissible) {
            handleDismissAlert();
        }

    };

    const onEmailCtaClick = () => {
        dispatch(srpActiveInteractionDuck.creators.setKeys({
            clickType: urgencyDriverLabel,
            parentId: urgencyDriverLabel,
            showEmailModal: true,
            emailCTAContext: analyticsContext,
        }));

        dispatch(srpActiveEmailListingDuck.creators.setActiveResults([listing?.id]));

        if (isDismissible) {
            cbhVehiclesCache.setItem({ listingIds: [...viewedVehicleIds, listing?.id] });
        }
    };

    const renderContactDealerCtas = (
        <div className={clsx({ 'w-100': lessThanSmall, 'align-items-center': !lessThanSmall }, 'margin-top-2 ')}>
            <div className="display-flex flex-1 ">
                <EmailCTA
                    buttonSize={isStillInterestedAlert ? 'small' : ''}
                    buttonStyle="default"
                    className="w-100"
                    clickType={urgencyDriverLabel}
                    context={analyticsContext}
                    onClick={onEmailCtaClick}
                    listingId={listing?.id}
                    showIcon={false}
                    type="block"
                    parentId={urgencyDriverLabel}
                />
            </div>

            {(ownerPhoneDetails && lessThanSmall) && (
                <OwnerPhoneNumber
                    analyticsData={analyticsData}
                    className="margin-left-2 display-flex"
                    key="srpListingAlertOwnerPhone"
                    phone={ownerPhoneDetails}
                    isCallable={lessThanSmall}
                    uiContext="glyph-button"
                    buttonStyle="default"
                    buttonSize={isStillInterestedAlert ? 'xsmall' : 'small'}
                />
            )}
        </div>
    );

    return (
        <div
            role="alert"
            className={clsx({ 'fade-in': isStillInterestedAlert }, 'srp-info-alert')}
            {...rest}
        >
            <div className="display-flex justify-content-between">
                <div className={clsx({ 'w-100': lessThanSmall, 'flex-column': lessThanSmall })}>
                    <div className={clsx({ 'display-flex': ((!displayMultipleImages) || (displayMultipleImages && !lessThanSmall)), 'flex-column': (displayMultipleImages && lessThanSmall) }, 'w-100')}>
                        <div className={clsx({ 'margin-bottom-2': (lessThanSmall && displayMultipleImages), 'w-100': (lessThanSmall && displayMultipleImages) }, 'display-flex')}>
                            {listingImages?.map((image, index) => (
                                <Image
                                    className={clsx({ 'margin-right-2': displayMultipleImages }, 'margin-top-1 margin-top-sm-0')}
                                    key={`listing-alert-image-${index}`}
                                    src={image?.src}
                                    alt={image?.alt}
                                    height={50}
                                    style={{ borderRadius: '3px' }}
                                />
                            ))}
                        </div>
                        <div className={clsx({ 'margin-left-4': (!lessThanSmall || !displayMultipleImages) }, 'display-flex flex-column w-100 align-self-center')}>
                            <Text
                                componentClass="div"
                                size={textSize || 300}
                            >
                                {alertTitle}
                            </Text>
                            <div className="display-flex flex-wrap flex-row ">
                                <Text
                                    componentClass="div"
                                    size={textSize || 300}
                                >
                                    <Button
                                        bsStyle="text"
                                        onClick={handleVdpLinkClick}
                                    >
                                        {listing?.title}
                                    </Button>
                                    {(vehiclePrice && (
                                        <>
                                            <span className="margin-horizontal-3 text-subdued">|</span>
                                            <Text color="gray-base">{formatCurrency(vehiclePrice)}</Text>
                                        </>
                                    ))}
                                </Text>
                            </div>
                        </div>
                    </div>

                    {displayEmailCta && renderContactDealerCtas}
                </div>
                {isDismissible && (
                    <Glyphicon
                        className="text-size-400 text-gray-base"
                        data-cmp="listingAlertCloseButton"
                        glyph="remove"
                        onClick={handleDismissAlert}
                    />
                )}
            </div>
        </div>
    );
}

export default SrpListingAlert;
