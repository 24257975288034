import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';
import _isEqual from 'lodash/isEqual';

import { ItemCard } from 'reaxl';
import { InventoryListingV2 } from 'reaxl-listing';

import InventoryDealerInfo from '@/components/InventoryDealerInfo/index.js';

/**
 *  This is an Inventory Alpha listing. It shows the inventory state
 *  of the alpha and controls it's look and feel
 *
 */
const renderBody = ({
    phoneClickHandler,
    listing = {},
    listingCTA,
    orientation,
    enableMyWallet,
    emailOwnerProps,
    uiContext,
    onGetDirectionsClick,
    onTileClick,
    lazyLoad,
    onImageClick,
    onPricingDetailClick,
    onSaveToggle,
    isSaved,
    onCompareClick,
    isCompared,
    showAlphaPaymentRibbon,
    onAlphaWalletClick,
    parentId,
    paymentsCTA,
    personalPaymentProps,
    updateListingPayments,
    onPaymentCTAClick,
    clickType,
    owner,
    ...rest
}) => {
    const loading = lazyLoad ? 'lazy' : 'eager';

    const extraDealerInfoProps = {
        phoneClickHandler,
        orientation,
        onTileClick,
        lazyLoad,
        listing,
        owner,
        uiContext,
        clickType,
        key: 'alpha-listing-extra-dealer-info',
    };

    const dealerInfoProps = {
        emailOwnerProps,
        loading,
        parentId,
        ...extraDealerInfoProps,
    };

    return (
        <div
            onClick={onImageClick}
            role="button"
            tabIndex="0"
            aria-label={`Get more details about this ${listing?.title || ''} from ${owner?.name || ''}`}
        >
            <InventoryListingV2
                className="border-0 margin-bottom-0"
                clickType={clickType}
                enableMyWallet={enableMyWallet}
                isSaved={isSaved}
                listing={listing}
                listingCTA={listingCTA}
                onPricingDetailClick={onPricingDetailClick}
                onSaveToggle={onSaveToggle}
                onCompareClick={onCompareClick}
                id={listing.id}
                isCompared={isCompared}
                orientation={orientation}
                secondaryContent={<InventoryDealerInfo {...dealerInfoProps} />}
                showListingPaymentRibbon={showAlphaPaymentRibbon}
                personalPaymentProps={personalPaymentProps}
                onMyWalletClick={onAlphaWalletClick}
                uiContext="alpha"
                showFooter={false}
                lazyLoad={lazyLoad}
                loading={loading}
                paymentsCTA={paymentsCTA}
                updateListingPayments={updateListingPayments}
                onPaymentCTAClick={onPaymentCTAClick}
                data-qaid={`cntnr-listing-${listing.id}`}
            />
        </div>
    );
};

function InventoryAlphaListing({
    clickType = '',
    emailOwnerProps,
    enableMyWallet,
    footer,
    isCompared,
    isSaved,
    lazyLoad = false,
    listing,
    listingCTA,
    onAlphaWalletClick = () => { },
    onCompareClick = () => { },
    onGetDirectionsClick = () => { },
    onImageClick = () => { },
    onPaymentCTAClick,
    onPricingDetailClick,
    onSaveToggle = () => { },
    onTileClick = () => { },
    orientation = 'landscape',
    parentId,
    paymentsCTA,
    personalPaymentProps = {},
    phoneClickHandler = () => { },
    showAlphaPaymentRibbon = false,
    uiContext = 'default',
    updateListingPayments,
    ...rest
}) {

    const rootClassName = clsx(rest.className, 'bg-periwinkle panel panel-default');
    const body = renderBody({
        phoneClickHandler,
        listing,
        listingCTA,
        orientation,
        enableMyWallet,
        emailOwnerProps,
        uiContext,
        onGetDirectionsClick,
        onTileClick,
        lazyLoad,
        onImageClick,
        onPricingDetailClick,
        onSaveToggle,
        onCompareClick,
        isCompared,
        isSaved,
        showAlphaPaymentRibbon,
        onAlphaWalletClick,
        parentId,
        paymentsCTA,
        personalPaymentProps,
        updateListingPayments,
        onPaymentCTAClick,
        clickType,
        ...rest,
    });

    delete rest.className;
    delete rest.uiContext;
    delete rest.showInventory;
    return (
        <div
            data-cmp="inventoryAlphaListing"
            {...rest}
            className={rootClassName}
        >
            <div className="padding-0">
                <ItemCard
                    className="bordered"
                    uiContext="landscape"
                    body={body}
                    footer={footer}
                />
            </div>
        </div>
    );
}

InventoryAlphaListing.propTypes = {
    /**
     * The callback function fires when owner phone is called
     */
    phoneClickHandler: PropTypes.func,
    /**
    * The className to apply to the root node
    */
    className: PropTypes.string,
    /**
     * The footer to be added to the bottom of the Listing
     */
    footer: PropTypes.node,
    /**
     * The Listing data to display
     */
    listing: PropTypes.object,
    /**
     * Handler for listing saving ( Takes an event, listing, and isSaved state )
     */
    onSaveToggle: PropTypes.func,
    /**
     * Handler for listing Compare ( Takes an event, listing, and isSaved state )
     */
    onCompareClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool,
    ]),
    /**
     * Handler for Get Directions link
     */
    onGetDirectionsClick: PropTypes.func,
    /**
     * Handler for image link
     */
    onImageClick: PropTypes.func,
    /**
     * the handler for the tile click
     */
    onTileClick: PropTypes.func,
    /**
     * The emailOwnerProps Object has the props to pass through to the ConnectedEmailOwner component.
     */
    emailOwnerProps: PropTypes.object,
    /**
     * Used to toggle visual saved state
     */
    isSaved: PropTypes.bool,
    /**
     * The listingCTA object has attributes passed to the anchor.
     */
    listingCTA: PropTypes.object,
    /**
     * analytics parameter
     */
    parentId: PropTypes.string,
    /**
     * The paymentsCTA object has attributes passed to Payments.
     */
    paymentsCTA: PropTypes.object,
    /**
     * The pricingDetail object has all pricing details from sale to msrp.
     */
    pricingDetail: PropTypes.object,
    /**
     * When true, the image will load when in the viewport
     */
    lazyLoad: PropTypes.bool,
    /**
     * uiContext to control display of view dealer inventory
     */
    orientation: PropTypes.oneOf([
        'portrait',
        'landscape',
    ]),
    /**
     * uiContext to control display of inventory listing side bar
     */
    uiContext: PropTypes.oneOf(['default', 'snapshot', 'sponsored', 'inventory']),
    /**
     * The showPaymentRibbon boolean to show/hide payment ribbon
     */
    showAlphaPaymentRibbon: PropTypes.bool,
    /**
     * handler for Alpha Wallet Update click
     */
    onAlphaWalletClick: PropTypes.func,
    /**
     * Object for personal payment
     */
    personalPaymentProps: PropTypes.object,
    /**
     * analytics parameter
     */
    clickType: PropTypes.string,
    /**
     * The Owner data to display
     */
    owner: PropTypes.object,
};

InventoryAlphaListing.displayName = 'InventoryAlphaListing';

export default React.memo(InventoryAlphaListing, (newProps, oldProps) => _isEqual(newProps, oldProps));
