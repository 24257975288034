import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import {
    Button,
    Glyphicon,
    Heading,
    IconButton,
    Image,
    Link,
} from 'reaxl';
import { useAnalytics } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import remoteActions from '@/actions/srp/remoteActions';

import {
    authDuck,
    queryDuck,
    userDuck,
} from '@/ducks';

import srpContentDuck from '@/ducks/srp/srpContentDuck';

import useSrpNavigation from '@/hooks/useSrpNavigation';

import FilterLocationModalContainer from './FilterLocationModalContainer';
import InventoryClearFilters from './InventoryClearFilters';
import KeywordSearchContainer from './KeywordSearchContainer';
import SaveSearchContainer from './SaveSearchContainer';
import SRPBreadcrumbsContainer from './SRPBreadcrumbsContainer';
import SuggestedFilterContainer from './SuggestedFilterContainer';

const renderHeading = ({ locationHeading }) => (
    <>
        <Heading
            componentClass="h1"
            size={500}
            className="display-inline margin-right-2"
            id="search-results-heading"
        >
            {locationHeading}
        </Heading>
        <FilterLocationModalContainer />
    </>
);

function KeywordButton({ onClick }) {
    return (
        <IconButton
            label="Search by keyword"
            glyph="search"
            hideLabel
            size="small"
            onClick={onClick}
            className="col-xs-1 padding-top-1"
        />
    );
}

export function SearchResultsTitleContainer() {
    const dispatch = useDispatch();
    const { sendClick } = useAnalytics();

    const device = useDevice();
    const lessThanMd = _get(device, 'lessThan.md', false);
    const isXs = _get(device, 'is.xs', false);

    const { locationHeading } = useSelector(srpContentDuck.selectors.getDuckState);
    const {
        searchBookmarkId = null,
        bookmarkId = null,
        sellerType = '',
    } = useSelector(queryDuck.selectors.getDuckState) || {};

    const savedSearch = useSelector(userDuck.selectors.getSavedSearch) || {};
    const savedSearchTitle = savedSearch.title;
    const isLoggedIn = useSelector(authDuck.selectors.isLoggedIn);

    const [keywordExpand, setKeywordExpand] = useState(false);
    const navigateToSrp = useSrpNavigation();

    const {
        personalization: [isPersonalizationEnabled] = [],
        enable_private_seller_exchange_logo: [enablePrivateSellerExchangeLogo],
        psx_logo: [, {
            icon: privateSellerIcon,
            href: privateSellerExchangeLogoHref,
        }],
        suggested_keyword: [enableKeyword],
    } = useFeatures([
        'personalization',
        'enable_private_seller_exchange_logo',
        'psx_logo',
        'suggested_keyword',
    ]);

    useEffect(() => {
        if (searchBookmarkId && isLoggedIn) {
            dispatch(remoteActions.loadSavedSearchBookmark());
        }
        if (bookmarkId) {
            dispatch(remoteActions.handleSearchRedirect(false, navigateToSrp));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchBookmarkId]);

    const renderFilterChipsControls = (
        <div className="display-flex flex-nowrap">
            <SaveSearchContainer
                key="saveSearchContainer"
            />
            <InventoryClearFilters
                key="clearFilters"
                className="margin-right-3"
            />
        </div>
    );

    const handleKeywordExpand = () => {
        setKeywordExpand(!keywordExpand);
    };

    const renderKeywordBlock = (
        <>
            <KeywordSearchContainer className="col-xs-10" />
            <div className="col-xs-1 align-self-center">
                <Button
                    bsStyle="link"
                    onClick={() => handleKeywordExpand()}
                >
                    <Glyphicon
                        glyph="remove"
                        className="text-gray-darkest"
                    />
                </Button>
            </div>
        </>
    );

    const onlyPrivateSellerSelected = !Array.isArray(sellerType) && sellerType === 'p';

    const handlePrivateSellerExchangeLogoClick = (e) => {
        sendClick('psxPrivateSellerExchangeLogoClick', e);
    };

    return (
        <div className="padding-horizontal-2 padding-horizontal-sm-0">
            {isPersonalizationEnabled
                ? (
                    <div className="text-gray-dark">
                        {savedSearchTitle}
                    </div>
                ) : null}
            {enablePrivateSellerExchangeLogo && onlyPrivateSellerSelected && (
                <Link
                    target="_blank"
                    href={privateSellerExchangeLogoHref}
                    onClick={handlePrivateSellerExchangeLogoClick}
                >
                    <Image
                        src={privateSellerIcon}
                        alt="Private Seller Exchange"
                        height={27}
                        lazyload={false}
                        loading="eager"
                    />
                </Link>
            )}
            <SRPBreadcrumbsContainer />
            <div className="display-md-flex align-items-md-center margin-top-2">
                {renderHeading({ locationHeading })}
            </div>
            <div className={clsx({ 'row margin-top-3': enableKeyword })}>
                {!isXs && (
                    <KeywordSearchContainer
                        className="col-sm-5 col-md-3 padding-vertical-1"
                        style={{ minWidth: '200px' }}
                    />
                )}

                {enableKeyword && isXs && !keywordExpand && <KeywordButton onClick={handleKeywordExpand} />}
                {enableKeyword && isXs && keywordExpand && renderKeywordBlock}

                <div className={clsx({ 'col-xs-11 col-sm-7 col-md-9': enableKeyword })}>
                    <div
                        className="display-flex flex-direction-row w-100"
                    >
                        {!lessThanMd && renderFilterChipsControls}
                        {!keywordExpand && (<div style={{ minWidth: '100px' }}><SuggestedFilterContainer /></div>)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchResultsTitleContainer;
