import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';
import _get from 'lodash/get';
import _has from 'lodash/has';

import { useDevice } from '@atc/react-device';

import {
    Image,
    List,
} from 'reaxl';
import { EmailCTA } from 'reaxl-email';
import {
    OwnerPhoneNumber,
    OwnerWebsiteCTA,
} from 'reaxl-molecules';
import { KBBDealerRatings } from 'reaxl-ratings-reviews';

/**
 *  This component displays dealer specific info in a position overlay grid which can be used on special types of
 *  listings like the AlphaShowcase
 *
 *  Layout it the same on landscape and portrait:
 *
 * ,-------------------------------------
 * | OwnerName         |                 |
 * | OwnerRating       |    OwnerLogo    |
 * |                   |    Tagline      |
 * |                   |.................|
 * | Address           |                 |
 * | PhoneNumber | CTA |                 |
 * | GetDirections     |  AccelerateTile |
 *  -------------------------------------
 *
 */
const renderKBBDealerRatings = (owner) => (
    <KBBDealerRatings
        className="margin-bottom-1"
        owner={owner}
        widgetType="inline"
    />
);

function InventoryDealerInfo({
    clickType,
    emailOwnerProps = {},
    lazyLoad = true,
    listing = {},
    onTileClick = () => { },
    owner = {},
    ownerTxtCTA,
    parentId,
    phoneClickHandler,
    uiContext,
    ...rest
}) {
    const {
        id: ownerId,
        logo,
        name,
        phone,
        rating,
        website = {},
        hideEmailForm,
    } = owner;
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const hasOwnerWebsite = !!website.href;
    const titleTextClasses = clsx('text-gray-base text-size-200', {
        'cursor-pointer': hasOwnerWebsite,
    });

    const analyticsData = {
        inventoryId: listing.id,
        ownerId,
        par: parentId,
        clickType,
    };

    const renderDealerLogo = () => logo && (
        <Image
            className="margin-bottom-1 cursor-pointer"
            alt="Dealer Logo"
            src={logo?.src}
            lazyload={lazyLoad}
            loading={lazyLoad ? 'lazy' : 'eager'}
            onClick={(event) => onTileClick(event, owner)}
            width={71}
            height={30}
        />
    );

    const renderOffsiteOwnerTitle = () => (
        <Fragment key="offsiteOwnerTitle">
            <OwnerWebsiteCTA
                className={titleTextClasses}
                href={website.href}
                label={name || listing.ownerName}
                analyticsData={analyticsData}
                uiContext="link"
            />
            {rating && renderKBBDealerRatings(owner)}
        </Fragment>
    );

    const renderOwnerTitle = () => (
        <Fragment key="ownerTitle">
            <span className={titleTextClasses}>
                {name}
            </span>
            {rating && renderKBBDealerRatings(owner)}
        </Fragment>
    );

    const renderOwnerPhone = () => _has(owner, 'phone.value') && (
        <OwnerPhoneNumber
            key="ownerPhoneNumber"
            phoneClickHandler={phoneClickHandler}
            className="text-gray-base text-size-200"
            phone={listing.phone || phone}
            isVisible
            isCallable={isXs}
            analyticsData={analyticsData}
        />
    );

    const showOwnerEmailCTA = !!emailOwnerProps && !hideEmailForm;

    const renderEmailOwner = () => (
        <EmailCTA
            clickType={clickType}
            parentId={emailOwnerProps.parentId}
            key="email-cta"
            listingId={listing.id}
            onClick={emailOwnerProps.onCTAClick}
            ownerId={ownerId}
            type="link"
            context="alpha"
        />
    );

    const renderDealerInfoList = () => {
        const items = [renderOwnerPhone()];
        if (showOwnerEmailCTA) {
            items.push(renderEmailOwner());
        }

        return (
            <List
                inline
                items={items}
                className="text-gray-base text-size-200"
            />
        );
    };

    return (
        <div {...rest}>
            <hr />
            <div className="row">
                <div className="col-xs-12 col-sm-9">
                    {hasOwnerWebsite ? renderOffsiteOwnerTitle() : renderOwnerTitle()}
                    <div
                        key="contactDealer"
                    >
                        <div className="display-flex flex-row justify-content-start flex-wrap margin-top-1">
                            {renderDealerInfoList()}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-3 padding-top-3 padding-top-sm-0 text-sm-right">
                    {renderDealerLogo()}
                </div>
            </div>
        </div>
    );
}

InventoryDealerInfo.propTypes = {
    /**
    * The className to apply to the root node
    */
    className: PropTypes.string,
    /**
     * Toggle to lazyLoad components
     */
    lazyLoad: PropTypes.bool,
    /**
     * The Listing data to display
     */
    listing: PropTypes.object,
    /**
     * The owner data to display in case a listing is not using this component
     */
    owner: PropTypes.object,
    /**
     * the handler for the tile click
     */
    onTileClick: PropTypes.func,
    /**
     * The emailOwnerProps Object has the props to pass through to the ConnectedEmailOwner component.
     */
    emailOwnerProps: PropTypes.object,
    /**
     * uiContext to control display of inventory listing side bar
     */
    uiContext: PropTypes.oneOf(['owner', 'ownerLite', 'inventory']),
    /**
     * The showPaymentRibbon boolean to show/hide payment ribbon
     */
    showAlphaPaymentRibbon: PropTypes.bool,
    /**
     * The showExtraDealerInfo boolean to show/hide the ExtraDealerInfo component
     */
    showExtraDealerInfo: PropTypes.bool,
    /**
     * analytics parameter
     */
    parentId: PropTypes.string,
    /**
     * Object for personal payment
     */
    personalPaymentProps: PropTypes.object,
};

export default InventoryDealerInfo;
