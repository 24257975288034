import React from 'react';

import ContentLoader from 'react-content-loader';

export default function FilterPlaceholder({ uniqueKey }) {

    const pos = [15, 40, 65, 90];

    return (
        <ContentLoader
            width="100%"
            height={90}
            speed={2}
            foregroundColor="#e1e4e8"
            backgroundColor="#f8f8f8"
            uniqueKey={`filterPlaceholder-${uniqueKey}`}
        >
            <rect
                x="0"
                y={pos[0]}
                width="10"
                height="10"
            />
            <rect
                x="25"
                y={pos[0]}
                width="100%"
                height="10"
            />
            <rect
                x="0"
                y={pos[1]}
                width="10"
                height="10"
            />
            <rect
                x="25"
                y={pos[1]}
                width="100%"
                height="10"
            />
            <rect
                x="0"
                y={pos[2]}
                width="10"
                height="10"
            />
            <rect
                x="25"
                y={pos[2]}
                width="100%"
                height="10"
            />
        </ContentLoader>
    );
}
