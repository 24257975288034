import { generateSrpSeoCacheId } from '@/utilities';

import { srpContentDuck } from '@/ducks/srp';

export default function withSelfServiceSeo() {
    return async (ctx) => {
        const {
            disable_srp_seo_self_serv: [disableSrpSeoSelfService],
        } = ctx.useFeatures([
            'disable_srp_seo_self_serv',
        ]);
        if (!disableSrpSeoSelfService && typeof window === 'undefined') {
            const { get } = await import('@bonnet/next/cache');
            // only get data if no location data in url
            const onlyUseNationalData = ctx.match.isNational;
            const selfServiceSeoCacheIdWithLocation = generateSrpSeoCacheId(ctx?.query, true);
            const selfServiceSeoCacheIdWithoutLocation = generateSrpSeoCacheId(ctx?.query, false);

            const [
                selfServiceSeoCacheDataWithLocation,
                selfServiceSeoCacheDataWithoutLocation,
            ] = await Promise.all([
                !onlyUseNationalData ? get({ cacheName: 'srp-seo', cacheKey: selfServiceSeoCacheIdWithLocation }) : null,
                get({ cacheName: 'srp-seo', cacheKey: selfServiceSeoCacheIdWithoutLocation }),
            ]);

            if (selfServiceSeoCacheDataWithLocation) {
                ctx.srpSelfServiceSeo = {
                    ...selfServiceSeoCacheDataWithLocation,
                    withLocation: true,
                };
            } else if (selfServiceSeoCacheDataWithoutLocation) {
                ctx.srpSelfServiceSeo = {
                    ...selfServiceSeoCacheDataWithoutLocation,
                    withLocation: false,
                };
            } else {
                ctx.srpSelfServiceSeo = {
                    id: selfServiceSeoCacheIdWithoutLocation,
                };
            }

            ctx.store.dispatch(srpContentDuck.creators.setKeys({
                srpSelfServiceSeo: ctx.srpSelfServiceSeo,
            }));
        }
    };
}
