import React from 'react';

import { useFeatures } from 'reaxl-features';
import { MyWalletGridContainer as ConnectedMyWalletGrid } from 'reaxl-wallet';

import { paymentsDuck } from '@/ducks';

function MyWalletGridContainer(props) {
    const {
        srp_wallet_grid_promo_card: [enableWalletPromoCard],
    } = useFeatures(['srp_wallet_grid_promo_card']);

    return (
        !enableWalletPromoCard
        && (
            <ConnectedMyWalletGrid
                uiContext={props.uiContext || 'basic'}
                paymentsDuck={paymentsDuck}
            />
        )

    );
}

export default MyWalletGridContainer;
