import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useCookies } from 'react-cookie';
import { connect, useDispatch, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import _capitalize from 'lodash/capitalize';
import _chunk from 'lodash/chunk';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

// bonnet
import { useDevice } from '@bonnet/next/device';

// @atc
import { eventBus } from '@atc/cai-event-bus';
import { mapDispatchToActionProp } from '@atc/modular-redux';

import {
    formattedNumber,
    getFirstPartyVistorId,
    getPixallId,
    injectScript,
    waitUntil,
} from 'atc-js';

import { SubHeading } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { brands, useBrand } from 'reaxl-brand';
import { createEmailData } from 'reaxl-email';
import { useFeatures } from 'reaxl-features';
import {
    InventoryListingPlaceholder,
    InventoryListingV2,
} from 'reaxl-listing';

import configureListingTiles from '@/utilities/configureListingTiles';
import getDynamicSpecifications from '@/utilities/dynamicSpecificationUtil';
import saveListing from '@/utilities/saveListing';
import getSpecificationKeys from '@/utilities/specificationUtil';
import vdpUrlBuilder from '@/utilities/vdpUrlBuilder';

import { getClickType, getParentId } from '@/analytics/searchResultsAnalyticsHelpers';

import {
    activeInteractionDuck,
    authDuck,
    compareListingsDuck,
    cpoContentDuck,
    globalConfigsDuck,
    ownersDuck,
    paymentsDuck,
    savedInventoryDuck,
    userDuck,
    userPreferencesDuck,
} from '@/ducks';

import {
    srpActiveEmailListingDuck,
    srpActiveInteractionDuck,
    srpAdsDuck,
    srpFiltersDuck,
    srpNewCarBoostDuck,
    srpPaginationDuck,
    srpPrimeSpotlightDuck,
    srpResultsDuck,
    srpSpotlightDuck,
    srpSupplementalDuck,
} from '@/ducks/srp';
import { vdpActiveInteractionDuck, vdpDealerDiffDuck } from '@/ducks/vdp';

// modules

import EmailOwnerFetcher from '@/fetchers/EmailOwnerFetcher';
import PaymentCalculationFetcher from '@/fetchers/PaymentCalculationFetcher';

import useVdpNavigation from '@/hooks/useVdpNavigation';

import { AlphaModule } from '@/modules';

import PersonalizationEngineModule from '@/modules/PersonalizationEngineModule';

// actions

// containers
import LazyComponent from '@/components/LazyComponent';

import usePremiumSpotlightUrgencyDrivers from './hooks/usePremiumSpotlightUrgencyDrivers';
import InventoryBoostContainer from './InventoryBoostContainer';
import InventoryQuickActionsContainer from './InventoryQuickActionsContainer';
import InventorySpotlightContainer from './InventorySpotlightContainer';
import ListingsLinerAds from './ListingsLinerAds';
import MyWalletGridContainer from './MyWalletGridContainer';
import MyWalletPlacementContainer from './MyWalletPlacementContainer';
import PremiumSpotlightContainer from './PremiumSpotlightContainer';
import PrivateSellerPlacementContainer from './PrivateSellerPlacementContainer';
import SRPDealerDiffModalWrapper from './SRPDealerDiffModalWrapper';
import SRPJumpLink from './SRPJumpLink';
import SRPListingCollection from './SRPListingCollection';
import WalletGridPromoCardContainer from './WalletPromoCardContainer';

// lazy
export const EVArticlesCarouselContainer = dynamic(() => import(
    /* webpackChunkName: "EVArticlesCarouselContainer" */
    '@/containers/srp/EVArticlesCarouselContainer'
), {
    ssr: false,
});
export const PreorderSearchContainer = dynamic(() => import(
    /* webpackChunkName: "PreorderSearchContainer" */
    '@/containers/srp/PreorderSearchContainer'
), {
    ssr: false,
    loading: () => (
        <InventoryListingPlaceholder
            className="col-xs-12 col-sm-4"
            data-cmp="inv-placeholder-preorder"
            key="preorder-inventory-placeholder"
        />
    ),
});
export const SupplementalSearchContainer = dynamic(() => import(
    /* webpackChunkName: "SupplementalSearchContainer" */
    '@/containers/srp/SupplementalSearchContainer'
), {
    ssr: false,
    loading: () => (
        Array(6).fill().map((_, index) => (
            <InventoryListingPlaceholder
                className="col-xs-12 col-sm-4"
                data-cmp="inv-placeholder-supplemental"
                key={`supplemental-placeholder-${index}`}
            />
        ))
    ),
});
export const SimilarInventoryContainer = dynamic(() => import(
    /* webpackChunkName: "SimilarInventoryContainer" */
    '@/containers/srp/SimilarInventoryContainer'
), {
    ssr: false,
    loading: () => (
        Array(6).fill().map((_, index) => (
            <InventoryListingPlaceholder
                className="col-xs-12 col-sm-4"
                data-cmp="inv-placeholder-similar"
                key={`similar-placeholder-${index}`}
            />
        ))
    ),
});
export const SpotlightCarouselContainer = dynamic(() => import(
    /* webpackChunkName: "SpotlightCarouselContainer" */
    '@/containers/srp/SpotlightCarouselContainer'
), { ssr: false });

export const SpotlightAlertCarouselContainer = dynamic(() => import(
    /* webpackChunkName: "SpotlightAlertCarouselContainer" */
    '@/containers/srp/SpotlightAlertCarouselContainer'
));

/*
 * render list of inventory listings as spotlights, regular and supplemental listings
 */
function InventorySearchResultsContainerComponent({
    actions,
    adsLazyLoadingOffset,
    birf,
    certifiedSponsor,
    filtersValues,
    hasAlphaListings,
    isLastPage,
    listings = [],
    savedListingIds,
    resultCount = 0,
    compareListingIds,
    currentPage,
    resultsPerPage,
    searchRadius,
    isLoggedIn,
}) {

    const dispatch = useDispatch();
    const { brand, isBrand } = useBrand();
    const isKbbBranded = isBrand(brands.KBB_BRAND);
    const isFordBranded = isBrand(brands.FORD_BRAND);

    const aemHost = useSelector(globalConfigsDuck.selectors.getAemHost);
    const showListingWalletPayment = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'paymentEmphasis'));
    const dealDetails = useSelector(paymentsDuck.selectors.getTransformedDealDetails, _isEqual);
    const userLocation = useSelector(userDuck.selectors.getCityState);
    const spotlights = useSelector(srpSpotlightDuck.selectors.getActiveInventory, _isEqual);
    const premiumSpotlights = useSelector(srpPrimeSpotlightDuck.selectors.getActiveInventory, _isEqual);
    const isResultsLoading = useSelector(srpResultsDuck.selectors.getResultLoading);
    const boostListings = useSelector(srpNewCarBoostDuck.selectors.getAvailableInventory, _isEqual);
    const lastPlacementInteraction = useSelector(srpActiveInteractionDuck.selectors.getLastPlacementInteraction);
    const supplementalResultsCount = useSelector(srpSupplementalDuck.selectors.getResultCount);
    const supplementalResultsLoaded = useSelector(srpSupplementalDuck.selectors.getLoadedCount);
    const owners = useSelector(ownersDuck.selectors.getOwners);
    const leadProfile = useSelector(userDuck.selectors.getLeadProfile);

    const urgencyDriverData = usePremiumSpotlightUrgencyDrivers({ spotlight: premiumSpotlights[0] });

    const [psxRotationComplete, setPsxRotationComplete] = useState(false);

    const isOneClickOptedIn = useSelector(activeInteractionDuck.selectors.isOneClickOptedIn);
    const { isOneClickOptedIn: leadProfileOptedIn } = leadProfile || {};
    const [oneClickListingIds, setOneClickListingIds] = useState([]);

    const previousListingsRef = useRef(listings);

    const [cookies = {}] = useCookies(['ATC_ID', 'pxa_ipv4']);

    const userZip = useSelector((state) => userDuck?.selectors?.getZip(state));

    useEffect(() => {
        // TODO: refactor this to not use the listings and instead usePageEvent for changing on a "new page"
        if (previousListingsRef.current !== listings) {
            previousListingsRef.current = listings;
        }
    }, [listings]);

    const changeWalletPlacement = previousListingsRef.current !== listings;

    useEffect(() => {
        if (changeWalletPlacement && lastPlacementInteraction === 'psxProtection') {
            setPsxRotationComplete(true);
        }
    }, [changeWalletPlacement, lastPlacementInteraction]);

    useEffect(() => {
        if (userDuck && !leadProfile) {
            dispatch(userDuck.creators.setLeadProfile());
        }
    }, []);

    const {
        accelerate: [, {
            enable_listing_badge: enableListingBadge,
        }],
        dealer_ratings: [isDealerRatingEnabled],
        disable_supplemental_listings: [disableSupplementalListings], // optimizely switch
        disable_similar_listings_srp: [disableSimilarListings],
        enable_360_image: [enable360Image],
        enable_new_car_boost: [enableNewCarBoost],
        enable_srp_mywallet_placement: [enableSrpMyWalletPlacement],
        est_payments: [isEstPaymentsEnabled],
        home_services: [isHomeServicesEnabled],
        personalization: [isPersonalizationEnabled, { listings_saved: authenticatedSavesOnly }],
        spotlight: [isSpotlightEnabled], // feature config
        supplemental: [supplementalEnabled],
        SRP_DEALERNAME_CLICKABLE: [isDealerNameClickable],
        brand: [, { base_url: baseUrl, leads_cc_flag: copySelf, leads_partner_name: partnerName }],
        one_click_lead: [isOneClickLeadEnabled],
        override_carfax_tile: [isOverrideCarfaxTileEnabled, { partner }],
        market_extension: [marketExtensionEnabled],
        my_wallet: [enableMyWallet],
        nds: ndsFeature,
        ev_articles_srp: [enableEVArticleSrp],
        disable_retail_engine: [disableRetailEngine],
        premium_spotlight: [enablePremiumSpotlight, {
            peekaboo_placement: peekabooPlacement,
            multi_carousel_3: enableMultiCarousel3,
            multi_carousel_9: enableMultiCarousel9,
            multi_alert_3: enableMultiAlert3,
            multi_alert_9: enableMultiAlert9,
        }],
        schema: [, { listing_type: schemaType, vehicle_feature_description: featureDescription, vehicle_price_description: priceDescription }],
        srp_wallet_grid_promo_card: [enableNewWalletPromoCard],
        remove_carfax_tile: [removeCarfaxTile],
        ev_articles: [isEVArticlesEnabled],
        psx_cta_webcomponents: [, { version = '', branch = 'main' }],
        psx_config: [, { environment = 'prod' }],
        psx_cta_srp: [isPsxCtaSrpEnabled],
    } = useFeatures([
        'accelerate',
        'dealer_ratings',
        'disable_spotlights',
        'disable_pkg_and_specs',
        'disable_supplemental_listings',
        'disable_similar_listings_srp',
        'est_payments',
        'enable_srp_mywallet_placement',
        'enable_360_image',
        'compare',
        'home_services',
        'enable_new_car_boost',
        'new_filter_badge',
        'simplify_listings',
        'spotlight',
        'supplemental',
        'personalization',
        'SRP_DEALERNAME_CLICKABLE',
        'brand',
        'one_click_lead',
        'override_carfax_tile',
        'market_extension',
        'my_wallet',
        'nds',
        'disable_retail_engine',
        'premium_spotlight',
        'schema',
        'srp_third_spotlight_index',
        'srp_wallet_grid_promo_card',
        'remove_carfax_tile',
        'ev_articles_srp',
        'ev_articles',
        'psx_cta_webcomponents',
        'psx_config',
        'psx_cta_srp',
    ]);

    const psxWebComponentUrl = `https://static.kbb.com/psx-listing-actions/${[branch, environment, version].filter(Boolean).join('/')}/psx-listing-actions.esm.js`;

    const schemaTemplate = {
        featureDescription,
        priceDescription,
    };

    const device = useDevice();
    const notXS = _get(device, 'greaterThan.xs', false);

    const [, {
        disable_payments: isPaymentsDisabled,
    }] = ndsFeature;

    const navigateToVdp = useVdpNavigation();

    // Clean up eventBus and script tag when component unmount
    useEffect(() => {
        if (isPsxCtaSrpEnabled) {
            return () => {
                ['psx.listingActions.openMakeOffer', 'psx.listingActions.makeOfferModalIsLoaded'].forEach((event) => window.caiEventBus.off(event));
                document.querySelector('script[src*="static.kbb.com/psx-listing-actions"]')?.remove();
            };
        }
        return () => { };
    }, [isPsxCtaSrpEnabled]);

    const {
        addListingToCompare = () => { },
        saveListing: saveInventoryListing,
        setActiveEmailListingResults,
        setActiveInteraction,
        setUserPreferencesDuck,
        setVDPActiveInteraction,
        updateListingPayments,
        updateMyWallet,
        hydrateUserInventory,
        hydrateRecentInventory = () => { },
        removeCompareListing = () => { },
        loadDealerDiff,
    } = actions;

    const hasSearchRadius = !!searchRadius;

    // Spotlight experiments
    const showPeekabooSpotlight = enablePremiumSpotlight && peekabooPlacement && !_isEmpty(urgencyDriverData);
    const enableSpotlightCarousel = enablePremiumSpotlight && premiumSpotlights.length > 2;

    // Show supplemental results condition
    const showSupplementalResults = isLastPage && hasSearchRadius;

    const isLowResultCount = resultCount + supplementalResultsCount <= 25;
    const isSupplementalDoneLoading = (supplementalResultsLoaded >= supplementalResultsCount) || supplementalResultsLoaded === 100;
    const showSimilarListings = !disableSimilarListings && isLowResultCount && isSupplementalDoneLoading && hasSearchRadius;

    const peData = useSelector((state) => {
        const allData = PersonalizationEngineModule.duck.selectors.selectPersonalization(state) || {};
        return {
            preferredFuel: _get(allData, 'PrefFuelCat', null),
            recentPreferredFuel: _get(allData, 'RecentPrefFuelCat', null),
            marketingSegments: _get(allData, 'pe_clusters', []),
        };
    }, _isEqual);

    const compareToggle = useSelector(userPreferencesDuck.selectors.getCompareToggle);
    const showEVArticleCarousel = (isEVArticlesEnabled && enableEVArticleSrp);

    const specificationKeys = useMemo(() => getSpecificationKeys(filtersValues, peData), [filtersValues, peData]);

    const getListingSpecificationKeys = useCallback((listing) => (listing.specifications ? specificationKeys.filter((key) => listing.specifications[key]) : []), [specificationKeys]);

    // Retrieve saved inventory ids and set them in the inventory reducer
    const initializeUserInfo = useCallback(() => {
        hydrateUserInventory({ isLoggedIn, onLoggedInStateReady: () => { } });
    }, [hydrateUserInventory, isLoggedIn]);

    // Retrieve recently viewed inventory ids and set them in the inventory reducer
    const initializeRecentInventory = useCallback(() => {
        hydrateRecentInventory({ isLoggedIn, onLoggedInStateReady: () => { } });
    }, [hydrateRecentInventory, isLoggedIn]);

    useEffect(() => {
        initializeRecentInventory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*
      * This useEffect hook is intended to fire only on component mount
      * and will request a fourth spotlight if necessary as well as
      * fire all initial page-loaded spotlight listings
      */
    useEffect(() => {
        // Initialize saved listing and other user data
        // Only call this function on KBB
        if (isKbbBranded) {
            initializeUserInfo();
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendDealerDiffCtaClick = useCallback((event, data) => {
        sendClick('inventoryDealerDiffClick', event, data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDealerDiffModalToggle = useCallback(() => dispatch(srpActiveInteractionDuck.creators.setKeys({ showDealerDiffModal: true })), [dispatch]);

    const handleDealerDiffCTAClick = useCallback((listing, clickType, paginatedListingIndex, listingOwner, parentId, event) => {
        event.stopPropagation();
        event.preventDefault();

        const dealerDiffEventResult = {
            prefix: 'text-link::listing::',
            ctaLabel: listingOwner?.dealerDiff?.label,
        };

        const dealerDiffCtaData = { listing, inventoryId: listing.id, clickType, eventResult: dealerDiffEventResult, paginatedListingIndex, par: parentId };

        const query = {
            dealerDiffCmsId: _get(listingOwner, 'dealerDiff.contentId', ''),
            listingType: _capitalize(listing.listingType),
        };

        loadDealerDiff(query, aemHost);

        handleDealerDiffModalToggle();

        sendDealerDiffCtaClick(event, {
            ctaType: _has(listingOwner, 'dealerDiff.tile.src') ? 'cta' : 'link',
            ...dealerDiffCtaData,
        });
    }, [
        aemHost,
        handleDealerDiffModalToggle,
        loadDealerDiff,
        sendDealerDiffCtaClick,
    ]);

    const handleTelMetric = useCallback((listing, phoneNumber, clickType) => {
        const data = {
            birf,
            clickType,
            listing,
            phoneNumber,
        };
        sendClick('telMetricsPhoneTextClick', {}, data);
    }, [birf]);

    const handleCompareListingsClick = useCallback((listing) => async (event) => {
        sendClick('compareListingsDrawerClick', event, {
            inventoryId: listing?.id,
            inheritPageData: false,
            par: 'comp_v_add',
            pixallData: {},
        });

        setUserPreferencesDuck({ showDrawer: true });
        addListingToCompare(listing?.id);
        if (compareListingIds && compareListingIds.includes(listing.id)) {
            removeCompareListing(listing?.id);
        }
    }, [setUserPreferencesDuck, addListingToCompare, compareListingIds, removeCompareListing]);

    const handleEmailOwnerClick = useCallback((listing, parentId, clickType) => async (event) => {
        if (!isOneClickOptedIn && !leadProfileOptedIn) {
            setActiveEmailListingResults([listing.id]);
            setActiveInteraction({
                ownerId: listing?.ownerId ?? '',
                parentId,
                showEmailModal: true,
                clickType,
                emailCTAContext: showPeekabooSpotlight ? 'peekaboo-spv' : '',
            });
        } else {
            event?.stopPropagation();
            event?.preventDefault();

            if (leadProfile?.firstName && leadProfile?.lastName && leadProfile?.email) {

                const data = createEmailData({
                    clickType,
                    cookies,
                    copySelf,
                    leadProfile,
                    listing,
                    oneClick: true,
                    originPage: 'srp',
                    partnerName,
                    PhoneNumber: leadProfile?.phone,
                    pixallFirstPartyId: getFirstPartyVistorId(),
                    pixallThirdPartyId: getPixallId() || 'nl',
                    userZip,
                });

                const emailResult = await EmailOwnerFetcher(data);

                if (emailResult?.email?.status === 'SUCCESS') {
                    sendClick('ownerEmailSent', event, {
                        inventoryId: listing?.id,
                        hasListingType: true,
                        includeWalletData: false,
                        isOneClick: true,
                        par: parentId,
                    });

                    const updatedListingIds = [
                        ...oneClickListingIds,
                        listing.id,
                    ];
                    setOneClickListingIds(updatedListingIds);
                }
            }
        }
    }, [isOneClickOptedIn, leadProfileOptedIn, setActiveEmailListingResults, setActiveInteraction, showPeekabooSpotlight, leadProfile, cookies, copySelf, partnerName, userZip, oneClickListingIds]);

    const handleEditEmailClick = useCallback((listing, parentId, clickType) => async (event) => {
        event.stopPropagation();
        event.preventDefault();
        setActiveEmailListingResults([listing.id]);
        setActiveInteraction({
            ownerId: listing?.ownerId ?? '',
            parentId,
            showEmailModal: true,
            showEditEmailModal: true,
            clickType,
        });
        sendClick('oneClickButtonClick', event, {
            listingId: listing.id,
            element: 'cta',
            content: 'edit',
            clickType,
        });
    }, [setActiveEmailListingResults, setActiveInteraction]);

    const handleListingAnalyticClick = useCallback(({ inventoryId, ctaType, clickType, parentId, paginatedListingIndex }) => async (event) => {
        let handler = 'inventoryClick';
        if (ctaType === 'certifiedTile') {
            handler = 'inventoryCertifiedTileClick';
        }
        const tagData = {
            inventoryId,
            par: parentId,
            clickType,
            paginatedListingIndex,
            pixallData: {
                eventSource: 'vehicle',
            },
        };
        await sendClick(handler, event, tagData);
    }, []);

    const handleListingNavigationClick = useCallback(({ inventoryId, ctaType, listing = {}, clickType, parentId, paginatedListingIndex, anchor, tileClick }) => async (event) => {
        dispatch(srpActiveInteractionDuck.creators.setKeys({
            showSpinningIndicator: true,
        }));

        // need to stop propagation and prevent default to allow anchors within listing for SEO purposes while using the
        // correct handlers
        event.stopPropagation();
        event.preventDefault();
        await handleListingAnalyticClick({ inventoryId, ctaType, clickType, parentId, paginatedListingIndex })(event);

        navigateToVdp({
            url: vdpUrlBuilder({ baseUrl: _get(listing, 'vdpBaseUrl'), clickType, anchor, tileClick }),
            listingId: listing.id,
        });

    }, [dispatch, handleListingAnalyticClick, navigateToVdp]);

    const handleListingItemClick = useCallback((listingItemData) => async (event) => {
        // prevent navigating to the vdp for links and CTA's that do not navigate
        event.stopPropagation();

        const { clickType, ctaType, inventoryId, paginatedListingIndex, parentId } = listingItemData;

        // ctaType = "product"
        let handler = 'inventoryProductClick';
        if (ctaType === 'payment') {
            handler = 'inventoryPaymentDetailsClick';
        }

        sendClick(handler, event, {
            inventoryId,
            par: parentId,
            clickType,
            paginatedListingIndex,
        });
    }, []);

    const handleListingWalletClick = useCallback((listingItemData) => async (event) => {
        event.stopPropagation();

        const { listing, listingCategory } = listingItemData;
        let par;
        switch (listingCategory) {
            case 'spotlight': {
                par = 'spv_lstg';
                break;
            }
            case 'supplemental': {
                par = 'sup_v_lstg';
                break;
            }
            case 'boost': {
                par = 'ncb';
                break;
            }
            default:
                par = 'v_lstg';
                break;
        }

        await sendClick('snapshotPaymentDetailsClick', event, {
            par, // Why are we using listingCategory for par?
            ...listingItemData,
        });

        updateMyWallet({
            listingId: listing.id,
            showPaymentDetailsModal: true,
            listingCategory,
        });
    }, [updateMyWallet]);

    const handlePaymentCTAClick = useCallback((paymentData) => async (event) => {
        event.stopPropagation();

        const { parentId } = paymentData;

        await sendClick('listingPaymentCTAClick', event, {
            par: parentId,
            ...paymentData,
        });

        updateMyWallet({
            displayModal: true,
        });
    }, [updateMyWallet]);

    const isActiveDeal = useCallback((listing) => {
        const dealVins = Object.keys(dealDetails).map((dealId) => dealDetails[dealId]?.vehicle?.vin);

        if (dealVins) {
            return dealVins.includes(listing?.vin);
        }

        return false;
    }, [dealDetails]);

    const handleSeeVehicleDetailsClick = useCallback(({ listing = {} }) => (event) => {

        sendClick('myWalletCheckDealStatusCTAClick', event, { inventoryId: listing?.id, filterValues: filtersValues });

    }, [filtersValues]);

    const handleCheckDealStatusClick = useCallback(({ listing = {} }) => async (event) => {
        event.stopPropagation();

        await sendClick('myWalletCheckDealStatusCTAClick', event, { inventoryId: listing?.id, filterValues: filtersValues });

        updateMyWallet({
            displayModal: true,
            selectedPanelName: 'activeDeals',
        });
    }, [filtersValues, updateMyWallet]);

    const handlePsxListingMakeOfferClick = useCallback(async (event, { listing = {} }) => {
        event.stopPropagation();
        const openMakeOfferEventBus = () => {
            eventBus.emit('psx.listingActions.openMakeOffer', {
                listingId: listing?.id?.toString(),
            });
            sendClick('psxMakeOfferClick', event, { inventoryId: listing?.id, filterValues: filtersValues });
        };

        if (!document.querySelector(`script[src="${psxWebComponentUrl}"]`)) {
            injectScript(psxWebComponentUrl, {
                elementAttributes: {
                    type: 'module',
                },
                rejectOnError: true,
            }).catch((error) => {
                // eslint-disable-next-line no-console
                console.error('Inject PSX webcomponents script failed with detail: ', error?.message);
            });
        }

        // if PSX web-components is not mounted totally, add eventBus to listen `componentDidLoad()` in BaseModal
        // Repo reference: https://ghe.coxautoinc.com/Consumer/psx-listing-actions-web-components/blob/main/app/src/subcomponents/Modal/BaseModal.tsx
        if (!document.querySelector('base-modal')) {
            eventBus.on('psx.listingActions.makeOfferModalIsLoaded', (_) => {
                // web-components modal has lazy load so try to wait it is totally hydrated on SRP.
                waitUntil(
                    () => !!document.querySelector('base-modal.hydrated'),
                    openMakeOfferEventBus,
                    500,
                    2000,
                );
            });
        } else {
            // if PSX web-components is mounted and hydrated, directly send eventBus to open modal.
            openMakeOfferEventBus();
        }
    }, [psxWebComponentUrl]);

    const personalPaymentProps = {
        fetcher: PaymentCalculationFetcher,
        paymentsEnabled: !disableRetailEngine,
        ndsFeature,
    };

    const getListingProps = (listingCategory, listing, index, paginatedListingIndex) => {
        let lazyLoad = true;
        if ((index === 0 && !hasAlphaListings)
            || (notXS
                && (listingCategory === 'spotlight' && index < 3)
                || (listingCategory === 'listings' && (index + Math.min(spotlights.length, 3)) < 3))) {
            lazyLoad = false;
        }

        let listingContext;
        switch (listingCategory) {
            case 'spotlight':
                listingContext = 'sponsored';
                break;
            case 'boost':
                listingContext = 'recommended';
                break;
            case 'peekaboo':
                listingContext = 'simplified';
                break;
            default:
                listingContext = 'default';
        }

        if (listing.marketExtension?.imeDmaMatch) {
            listingCategory = 'ime';
        }

        const clickType = getClickType(listingCategory, urgencyDriverData.type);
        const parentId = getParentId(listingCategory, index, urgencyDriverData.type);

        const analyticsData = {
            inventoryId: listing?.id,
            clickType,
            parentId,
            paginatedListingIndex,
            listing,
            listingCategory,
        };

        const listingOwner = owners[listing.ownerId] || {};
        // TODO: to prevent the need to bind `listing` to `handleListingClick`, etc., InventoryListing should be modified
        // to pass `listing` back to `onClick` handlers the same way it does for `onSaveToggle` handlers.
        const listingSpecificationKeys = getListingSpecificationKeys(listing);
        const selectedMakeCode = _get(filtersValues, 'makeCodeList', '') || _get(filtersValues, 'makeCode', '');
        const isDynamicListingsEnabled = !selectedMakeCode;
        const dynamicSpecifications = isDynamicListingsEnabled ? getDynamicSpecifications(filtersValues, listing) : [];
        const onDealerDiffCTAClick = {
            onDealerDiffCTAClick: handleDealerDiffCTAClick.bind(null, listing, clickType, paginatedListingIndex, listingOwner, parentId),
        };
        const payments = ((enableMyWallet && isPaymentsDisabled) || !isEstPaymentsEnabled) ? null : _get(listing, 'pricingDetail.payments', null);
        const dealerHomeServices = isHomeServicesEnabled ? _get(listingOwner, 'homeServices', {}) : null;
        const dealerRatings = isDealerRatingEnabled ? _get(listingOwner, 'rating', {}) : null;
        const ownerWebsite = isDealerNameClickable ? _get(listingOwner, 'website', {}) : null;
        const isDealerDeliveryAvailable = marketExtensionEnabled ? _get(listingOwner, 'shippingLabel', '') : null;
        const listingCTAHref = vdpUrlBuilder({ baseUrl: _get(listing, 'vdpBaseUrl'), clickType });

        const onSaveToggle = (event, savedListing, wasSaved) => {
            saveInventoryListing({
                event,
                listing: {
                    ...savedListing,
                    tier: {
                        name: listingCategory,
                        index,
                        ...(showPeekabooSpotlight ? { urgencyDriverType: urgencyDriverData.type } : {}),
                    },
                },
                wasSaved,
                sendClick,
            });
        };

        const onToggleKBBDealerRatingModal = (event) => {
            event.stopPropagation();

            setVDPActiveInteraction({
                showKBBDealerModal: true,
            });

            handleListingNavigationClick({ ctaType: 'inventory', ...analyticsData })(event);
        };

        let listingTitleWithNoPkg = (listing.title) && (listing.title).substring(0, (listing.title).indexOf(' w/'));
        const showCompareButton = compareToggle && compareListingIds?.length < 5;

        // TODO: this should use a brand config, not check for isBrand
        const listingQuickActions = isFordBranded ? null : (
            <InventoryQuickActionsContainer
                clickType={clickType}
                listing={listing}
                onEmailClick={handleEmailOwnerClick}
                parentId={parentId}
                phoneClickHandler={handleTelMetric}
            />
        );

        // Remove listingType from listing title when only 1 type is selected in condition filter
        // Display certified title only when used condition filter is selected
        if (filtersValues.listingType && filtersValues.listingType.length === 1 && (filtersValues.listingType.includes('CERTIFIED')
            || filtersValues.listingType.includes('NEW') || (filtersValues.listingType.includes('USED') && listing.listingType !== 'CERTIFIED'))) {
            const make = listing?.make?.name || listing?.make;
            const model = listing?.model?.name || listing?.model;
            const trim = listing?.trim?.name || '';

            listingTitleWithNoPkg = `${listing.year} ${make} ${model} ${trim}`;
        }

        return {
            className: 'cursor-pointer display-flex flex-column',
            clickType,
            emailOwnerProps: {
                parentId,
                onCTAClick: handleEmailOwnerClick(listing, parentId, clickType),
                context: showPeekabooSpotlight ? 'peekaboo-spv' : '',
                labelKey: 'checkAvailability',
            },
            handleEditEmail: handleEditEmailClick(listing, parentId, clickType),
            id: String(listing.id),
            isActiveDeal: isActiveDeal(listing),
            isPhoneNumberCallable: !notXS,
            isSaved: savedListingIds.includes(listing.id),
            isCompared: compareListingIds && compareListingIds.includes(listing.id),
            show360: enable360Image && _get(listing, 'fyuseId', false),
            key: `listing_${index}`,
            lazyLoad,
            loading: lazyLoad ? 'lazy' : 'eager',
            listing: {
                ...listing,
                isHot: listing.isHot,
                title: listingTitleWithNoPkg,
                pricingDetail: {
                    ...listing.pricingDetail,
                    payments,
                },
                owner: {
                    ...listingOwner,
                    dealerDiff: listingOwner.dealerDiff && Object.assign(listingOwner.dealerDiff, { label: listingOwner.dealerDiff?.tagline }),
                    distanceFromSearch: listingOwner.distanceFromSearch && Math.round(listingOwner.distanceFromSearch * 100) / 100 || null,
                    homeServices: dealerHomeServices,
                    phone: listing.phone, // add back the phone that we saved earlier in dispatchutils specific to the listing
                    rating: dealerRatings,
                    shippingLabel: isDealerDeliveryAvailable,
                    website: ownerWebsite,
                },
                website: {
                    href: listingCTAHref,
                },
            },
            listingCTA: {
                title: listing.title,
                href: listingCTAHref,
            },
            listingQuickActions,
            onCertifiedTileClick: handleListingNavigationClick({ ctaType: 'certifiedTile', ...analyticsData, anchor: 'warrantySection', tileClick: 'true' }),
            onClick: handleListingNavigationClick({ ctaType: 'inventory', ...analyticsData }),
            onContextMenu: handleListingAnalyticClick({ ctaType: 'inventory', ...analyticsData }),
            onPricingDetailClick: handleListingNavigationClick({ ctaType: 'pricing', ...analyticsData, anchor: 'pricingSection' }),
            onProductTileClick: handleListingItemClick({ ctaType: 'product', ...analyticsData }),
            onProductTileLinkClick: handleListingItemClick({ ctaType: 'product', ...analyticsData }),
            phoneClickHandler: (phoneNumber) => handleTelMetric(listing, phoneNumber, clickType),
            oneClickEmailSent: oneClickListingIds.includes(listing.id),
            onSaveToggle: isPersonalizationEnabled || authenticatedSavesOnly ? onSaveToggle : null,
            onCompareClick: showCompareButton && handleCompareListingsClick(listing),
            'data-qaid': listingCategory === 'spotlight' ? '' : `cntnr-listing-${listing.id}`, // TODO remove in favor of data-cmp
            orientation: 'portrait',
            paymentsCTA: {
                onClick: handleListingItemClick({ ctaType: 'payment', ...analyticsData }),
            },
            parentId,
            showEditEmail: isOneClickLeadEnabled && (isOneClickOptedIn || leadProfileOptedIn),
            showEllipses: true,
            showDistance: _get(listingOwner, 'distanceFromSearch', 0) > 0,
            showPackages: true,
            specificationKeys: listingSpecificationKeys,
            dynamicSpecifications,
            showListingPaymentRibbon: !!enableMyWallet && (!!showListingWalletPayment || listingContext === 'recommended'),
            enableMyWallet: !!enableMyWallet,
            onMyWalletClick: handleListingWalletClick(analyticsData),
            updateListingPayments,
            personalPaymentProps,
            uiContext: listingContext,
            ...onDealerDiffCTAClick,
            onPaymentCTAClick: handlePaymentCTAClick(analyticsData),
            paginatedListingIndex,
            onOnlinePaperworkClick: handleListingNavigationClick({ ctaType: 'homeServices', ...analyticsData, anchor: 'nativeDealContainer' }),
            onActiveDealDetailsClick: handleSeeVehicleDetailsClick({ listing }),
            onCheckStatusClick: handleCheckDealStatusClick({ listing }),
            onToggleModal: onToggleKBBDealerRatingModal,
            onSelectPsxListing: isPsxCtaSrpEnabled ? (event) => handlePsxListingMakeOfferClick(event, analyticsData) : () => {},
        };
    };

    /**
     * render a special listing that is not part of the base listings call
     *
     * @param listingIndex index at which the spotlight should be added
     * @param listingCategory type of special listing
     * @returns {*} the markup with spotlight(s) being injected in the results
     */

    const renderSpecialListing = (listingIndex, listingCategory = 'spotlight') => {
        const listingProps = {
            baseUrl,
            getListingProps,
            schemaTemplate,
            schemaType,
            userLocation,
            currentPage,
            listingIndex,
            brand,
            certifiedSponsor,
            isOverrideCarfaxTileEnabled,
            partner,
            removeCarfaxTile,
        };

        if (listingCategory === 'spotlight' && !!isSpotlightEnabled) {
            return (
                <InventorySpotlightContainer
                    key={`spotlight-${listingIndex}`}
                    {...listingProps}
                />
            );
        }

        if (listingCategory === 'boost') {
            return (
                <InventoryBoostContainer
                    key={`boost-${listingIndex}`}
                    {...listingProps}
                />
            );
        }

        if (listingCategory === 'peekaboo') {
            return (
                <PremiumSpotlightContainer
                    premiumSpotlight={premiumSpotlights[0]}
                    urgencyDriverData={urgencyDriverData}
                    {...listingProps}
                />
            );
        }

        return () => { };
    };

    const listingsWithTiles = configureListingTiles({
        brand,
        certifiedSponsor,
        items: listings,
        isOverrideCarfaxTileEnabled,
        partner,
        tier: 'listing',
        removeCarfaxTile,
    });

    const renderResultsCount = (count) => (
        <SubHeading
            componentClass="h2"
            className="padding-bottom-4"
            size={500}
            data-cmp="resultsCount"
        >
            {formattedNumber({ value: count }) + ' Results'}
            <SRPJumpLink />
        </SubHeading>
    );

    const renderEVArticleCarousel = showEVArticleCarousel && (
        <LazyComponent
            key="EVArticlesCarousel"
        >
            <div className="col-xs-12 margin-bottom-5">
                <EVArticlesCarouselContainer />
            </div>
        </LazyComponent>
    );

    // TODO: maybe just set to invisible instead of unmounting when there are not enough listings?
    const renderLinerAd = (index) => (
        <div
            className="col-xs-12 margin-bottom-5"
            key={`liner-ad-${index}`}
        >
            <ListingsLinerAds
                adsLazyLoadingOffset={adsLazyLoadingOffset}
                index={index}
                notXs={notXS}
            />
        </div>
    );

    const renderSupplementalListings = !!supplementalEnabled && !disableSupplementalListings && (
        <SupplementalSearchContainer
            getListingProps={getListingProps}
            listingsWithTilesLength={listingsWithTiles.length}
            showSupplementalResults={showSupplementalResults}
        />
    );

    const searchResultsListings = listingsWithTiles?.map((listing, index) => {
        const paginatedListingIndex = (index + 1) + ((currentPage - 1) * resultsPerPage);

        if (!enableListingBadge) {
            listing.accelerate = false;
        }

        return isResultsLoading
            ? (
                <InventoryListingPlaceholder
                    className="col-xs-12 col-sm-4"
                    data-cmp="inv-placeholder-lstg"
                    key={`inventory-placeholder-${index}`}
                />
            ) : (
                <div
                    className="col-xs-12 col-sm-4 display-flex"
                    key={`listing-${index}`}
                >
                    <InventoryListingV2 {...getListingProps('listings', listing, index, paginatedListingIndex)} />
                </div>
            );
    });

    /*
     * Inject 4 Spotlights into the SRP results if available:
     * 2 Premium spotlights between the Alpha and the search results
     * 1 Premium spotlight after the 5th listing of the search results
     * 1 Non-Premium spotlight after the 20th listing. Show a Featured over a Standard spotlight if there are the same
     *  or more featured than standard organic listings
     */
    /* eslint-disable no-unused-expressions*/
    // insert spotlights at specific indexes
    [0, 1, 2, 23].forEach((position, index) => {
        spotlights?.[index]?.id && searchResultsListings.splice(position, 0, renderSpecialListing(index));
    });

    /*
    * Inject My Wallet promo grid placement at spot #4
    */
    if (enableNewWalletPromoCard) {
        searchResultsListings.splice(3, 0, <WalletGridPromoCardContainer key="myWalletPromoCard" />);
    }

    /*
     * Inject 2 New Car Boost listings if available
     * first one at index 6 or the first listing in the 3rd row on desktop
     * second at index 21 if there are 75 or more results per page
     */
    if (enableNewCarBoost) {
        boostListings[0] && searchResultsListings.splice(6, 0, renderSpecialListing(0, 'boost'));
        if (resultsPerPage >= 75 && resultCount >= 75) {
            boostListings[1] && searchResultsListings.splice(22, 0, renderSpecialListing(1, 'boost'));
        }
    }

    if (showPeekabooSpotlight) {
        premiumSpotlights[0] && searchResultsListings.splice(3, 0, renderSpecialListing(3, 'peekaboo'));
    }

    const isPrivateSellerPage = filtersValues?.sellerType?.length === 1 && filtersValues?.sellerType?.includes('p');
    const showPrivateSellerPlacement = isPrivateSellerPage && !psxRotationComplete;

    /*
    * if on a private seller page, inject placement in spot #10 before turning to wallet placements
    */
    if (enableSrpMyWalletPlacement && showPrivateSellerPlacement) {
        searchResultsListings.splice(9, 0, <PrivateSellerPlacementContainer changePlacement={changeWalletPlacement} />);
    }

    /*
     * Inject My Wallet placement at spot #10
     */
    const showWalletPlacement = enableSrpMyWalletPlacement && !showPrivateSellerPlacement;
    if (searchResultsListings.length > 9 && showWalletPlacement) {
        searchResultsListings.splice(9, 0, (<MyWalletPlacementContainer
            key="walletPlacementContainer"
            changePlacement={changeWalletPlacement}
        />
        ));
    }

    const renderSpotlightCarouselIndex3 = enableSpotlightCarousel && enableMultiCarousel3 && <SpotlightCarouselContainer />;
    const renderSpotlightCarouselIndex9 = enableSpotlightCarousel && enableMultiCarousel9 && <SpotlightCarouselContainer />;
    const renderSpotlightAlertCarouselIndex3 = enableSpotlightCarousel && enableMultiAlert3 && <SpotlightAlertCarouselContainer />;
    const renderSpotlightAlertCarouselIndex9 = enableSpotlightCarousel && enableMultiAlert9 && <SpotlightAlertCarouselContainer />;

    // chunk all search results listings in sets of 3 representing each row [[item, item, item], [item, item, item], ...]
    const chunkedListingsSet = _chunk(searchResultsListings, 3);

    // insert 3-column-wide components at specific indexes of chunkedListingsSet
    searchResultsListings.length > 6 && chunkedListingsSet.splice(1, 0, [renderSpotlightCarouselIndex3, renderSpotlightAlertCarouselIndex3]);
    searchResultsListings.length > 6 && chunkedListingsSet.splice(3, 0, renderLinerAd(0));
    searchResultsListings.length > 6 && chunkedListingsSet.splice(5, 0, [renderSpotlightCarouselIndex9, renderSpotlightAlertCarouselIndex9]);
    searchResultsListings.length > 14 && chunkedListingsSet.splice(7, 0, [renderLinerAd(1), renderEVArticleCarousel]);
    searchResultsListings.length > 19 && chunkedListingsSet.splice(10, 0, renderLinerAd(2));
    searchResultsListings.length > 33 && chunkedListingsSet.splice(13, 0, renderLinerAd(3));
    searchResultsListings.length > 36 && chunkedListingsSet.splice(16, 0, renderLinerAd(4));

    const showWalletGrid = currentPage === 1 || resultCount === 0;
    const renderMyWalletGrid = enableMyWallet && showWalletGrid && (<div className="col-xs-12"><MyWalletGridContainer uiContext="default" /></div>);

    const renderResultsLayout = (
        <div className="row display-flex flex-wrap margin-top-3">
            {chunkedListingsSet.slice(0, 3).flatMap((listing) => listing)}

            {renderMyWalletGrid}

            {chunkedListingsSet.slice(3, chunkedListingsSet.length).flatMap((listing) => <LazyComponent>{listing}</LazyComponent>)}

            {isLastPage && <PreorderSearchContainer />}

            {isLastPage && renderSupplementalListings}

            {isLastPage && showSimilarListings && <SimilarInventoryContainer getListingProps={getListingProps} />}

            <SRPListingCollection />
        </div>
    );

    // render liner ads outside of a loading indicator in order to prevent ads unmounting
    return (
        <div data-qaid="cntnr-listings">

            {renderResultsCount(resultCount)}

            {renderResultsLayout}

            <SRPDealerDiffModalWrapper />

            {isPsxCtaSrpEnabled && (
                <lead-generation
                    layout="srpListing"
                    is-director
                />
            )}
        </div>
    );
}

export const InventorySearchResultsContainer = memo(
    InventorySearchResultsContainerComponent,
    (oldProps, newProps) => _isEqual(newProps.savedListingIds, oldProps.savedListingIds)
        && _isEqual(newProps.listings, oldProps.listings)
        && _isEqual(newProps.compareListingIds, oldProps.compareListingIds)
        && _isEqual(newProps.filtersValues, oldProps.filtersValues)
);

const mapStateToProps = (state) => {
    const currentPage = srpPaginationDuck.selectors.getCurrentPage(state);
    let numRecords = srpPaginationDuck.selectors.getNumRecords(state);
    const savedListingIds = savedInventoryDuck.selectors.getSavedInventoryIds(state);
    const compareListingIds = compareListingsDuck.selectors.getActiveListingIds(state);

    const listings = srpResultsDuck.selectors.getActiveInventory(state);
    const hasAlphaListings = AlphaModule.duck.selectors.hasAlphaListings(state);
    const searchRadius = srpFiltersDuck.selectors.getSelectedSearchRadius(state);
    const resultCount = srpResultsDuck.selectors.getResultsCount(state);
    const isLastPage = currentPage >= Math.ceil(resultCount / numRecords);

    const filtersValues = srpFiltersDuck.selectors.getRequestValues(state);
    const isLoggedIn = authDuck.selectors.isLoggedIn(state);
    const certifiedSponsor = cpoContentDuck.selectors.selectCertifiedContentSimple(state);

    const resultPerPage = state.brand === brands.FORD_BRAND && numRecords === 25 ? numRecords = 30 : numRecords;

    return {
        birf: _get(state, 'birf.pageData.page.BIRF', {}),
        currentPage,
        resultsPerPage: resultPerPage,
        adsLazyLoadingOffset: srpAdsDuck.selectors.getAdsLazyLoadingOffset(state),
        certifiedSponsor,
        hasAlphaListings,
        listings,
        savedListingIds,
        compareListingIds,
        resultCount,
        searchRadius,
        isLastPage,
        filtersValues,
        isLoggedIn,
    };
};

const mapDispatchToProps = mapDispatchToActionProp({
    addListingToCompare: compareListingsDuck.creators.addToCompare,
    saveListing,
    setActiveEmailListingResults: srpActiveEmailListingDuck.creators.setActiveResults,
    setActiveInteraction: srpActiveInteractionDuck.creators.setKeys,
    setUserPreferencesDuck: userPreferencesDuck.creators.setKeys,
    setVDPActiveInteraction: vdpActiveInteractionDuck.creators.setKeys,
    updateListingPayments: paymentsDuck.creators.updateListingPayments,
    updateMyWallet: paymentsDuck.creators.updateMyWallet,
    hydrateUserInventory: userDuck.creators.hydrateUserInventory,
    hydrateRecentInventory: userDuck.creators.hydrateRecentInventory,
    removeCompareListing: compareListingsDuck.creators.removeFromCompare,
    loadDealerDiff: vdpDealerDiffDuck.creators.loadDealerDiff,
});

export default connect(mapStateToProps, mapDispatchToProps)(InventorySearchResultsContainer);
