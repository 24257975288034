import { useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useAnalyticsListener } from 'reaxl-analytics';

import useSearchResultsAnalytics from '@/analytics/useSearchResultsAnalytics';

import { srpActiveInteractionDuck, srpPaginationDuck } from '@/ducks/srp';

const AnalyticsWrapper = () => {
    const dispatch = useDispatch();

    useAnalyticsListener();

    const pagination = useSelector(srpPaginationDuck.selectors.getDuckState);
    const listingCount = useRef(false); // TODO refactor this garbage too

    useSearchResultsAnalytics({
        listingCount: listingCount.current,
        currentPage: pagination.currentPage,
        totalNumberListingsImpressionsCallback: () => {
            listingCount.current = true;
        },
        regularInventoryImpressionsCallback: () => {
            // reset active owner on page change
            dispatch(srpActiveInteractionDuck.creators.setKeys({
                ownerId: '',
            }));
        },
    });
};

export default AnalyticsWrapper;
