import React from 'react';

import FilterPlaceholder from './FilterPlaceholder';

function FilterAccordionsPlaceholder() {
    const accordions = [];
    const renderPlaceholders = () => {
        for (let i = 0; i < 20; i++) {

            accordions.push(<FilterPlaceholder key={`accodion-placeholder-${i}`} />);

        }
        return accordions;
    };
    return (
        <div data-cmp="filterAccordionsPlaceholder">
            {renderPlaceholders()}
        </div>
    );
}

export default FilterAccordionsPlaceholder;
