import React from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'reaxl';
import { useFeatures } from 'reaxl-features';

import { srpContentDuck, srpFiltersDuck } from '@/ducks/srp';

const capitalFirst = (s) => {
    // Ensure 's' is a string and not null or undefined
    if (typeof s !== 'string' || s.length === 0) {
        return s;
    }

    // Capitalize the first letter and make the rest lowercase
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

function SRPJumpLink() {

    const {
        srp_seo_jump_link: [enableSeoJumpLink],
    } = useFeatures([
        'srp_seo_jump_link',
    ]);

    const srpContent = useSelector(srpContentDuck.selectors.getDuckState);
    const { editorials, locationHeading } = srpContent;
    const filtersValues = srpFiltersDuck.selectors.getRequestValues;

    return enableSeoJumpLink && !!editorials?.length && (
        <Link
            className="margin-left-4 text-size-300"
            href="#model-reference-container"
            label={`Learn more about the ${locationHeading.replace(capitalFirst(filtersValues?.listingType?.[0]), '').replace('for Sale', '')}`}
        />
    );
}

export default React.memo(SRPJumpLink);
