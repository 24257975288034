import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useAnalytics } from 'reaxl-analytics';
import { PromoCard } from 'reaxl-listing';

import { srpActiveInteractionDuck } from '@/ducks/srp';

const placementValues = {
    psxFinance: {
        buttonText: 'Learn More',
        label: 'psxFinance',
        header: 'Need Same Day Financing?',
        href: 'https://www.autotrader.com/marketplace/financing',
        subText: "Why wait for your next paycheck or your bank? Our closing process is geared for instant approvals. You'll know your APR within seconds.",
    },
    psxWarranties: {
        buttonText: 'Shop Warranties',
        label: 'psxWarranties',
        header: 'Need Warranties?',
        href: 'https://www.autotrader.com/marketplace/vehicle-service-contracts',
        subText: 'Protect yourself from unexpected repair costs. Most plans include a $0 deductible, extensive coverage, towing, roadside assitance, and rental car allowance.',
    },
    gapProtection: {
        buttonText: 'Learn More',
        label: 'psxProtection',
        header: 'Need GAP Protection?',
        href: 'https://www.autotrader.com/marketplace/gap-insurance',
        subText: 'We offer GAP Protection for sudden cash expenses, car depreciation, or low down payment financing.',
    },
};

const iconUrl = 'https://www.autotrader.com/content/static/img/psx/kbb_srp_privatesellerexchange_logo.svg';

function PrivateSellerPlacementContainer({ changePlacement }) {

    const dispatch = useDispatch();

    const [displayValues, setDisplayValues] = useState(placementValues.psxFinance);

    const lastPlacementInteraction = useSelector(srpActiveInteractionDuck.selectors.getLastPlacementInteraction);

    const { buttonText, header, href, label, subText } = displayValues;

    const { sendClick } = useAnalytics();

    const handleCtaClick = () => {
        dispatch(srpActiveInteractionDuck.creators.setKeys({
            lastPlacementInteraction: label,
        }));
        sendClick('psxPrivateSellerPlacementClick', {}, { buttonText, header });

        window.open(href);
    };

    useEffect(() => {
        if (changePlacement) {
            if (lastPlacementInteraction === 'psxFinance') {
                setDisplayValues(placementValues.psxWarranties);
            }

            if (lastPlacementInteraction === 'psxWarranties') {
                setDisplayValues(placementValues.gapProtection);
            }
        }

    }, [changePlacement, lastPlacementInteraction]);

    return (
        <PromoCard
            buttonStyle={{ width: '100%' }}
            buttonText={buttonText}
            data-cmp="psxPlacement"
            handleButtonClick={handleCtaClick}
            heading={header}
            imgHeight={36}
            imgSrc={iconUrl}
            subText={subText}
        />
    );
}

export default PrivateSellerPlacementContainer;
