import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import processPricingPath from '@/utilities/processPricingPath';

import { srpDuckPreset } from '@/ducks/presets';

import CrawlPathModelYearsFetcher from '@/fetchers/CrawlPathModelYearsFetcher';
import CrawlPathTrimsFetcher from '@/fetchers/CrawlPathTrimsFetcher';

const srpSEOPricingDuck = objectDuckCreator({
    ...srpDuckPreset,
    store: 'pricing',
    initialState: {
        modelYearsData: [],
        trimsData: [],
    },
}).extend({
    creators: () => ({
        loadModelYearsData: () => async (dispatch, getState) => {
            const state = getState();
            const query = state?.query;
            const modelYearsCrawlPathData = CrawlPathModelYearsFetcher(query);
            const { links = [] } = await modelYearsCrawlPathData;

            const updatedModelYearLinks = await processPricingPath({
                data: links,
                query,
                isModelYearData: true,
            });

            await dispatch(srpSEOPricingDuck?.creators?.setKeys({
                modelYearsData: updatedModelYearLinks,
            }));
        },
        loadTrimsData: () => async (dispatch, getState) => {
            const state = getState();
            const query = state?.query;
            const trimCrawlPathData = CrawlPathTrimsFetcher(query);
            const { links = [] } = await trimCrawlPathData;

            const updatedTrimLinks = await processPricingPath({
                data: links,
                query,
                isModelYearData: false,
            });

            await dispatch(srpSEOPricingDuck?.creators?.setKeys({
                trimsData: updatedTrimLinks,
            }));
        },
    }),
    selectors: () => ({
        getModelYears: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ modelYearsData }) => modelYearsData,
        )),
        getTrims: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ trimsData }) => trimsData,
        )),
    }),
});

export default srpSEOPricingDuck;
