import React from 'react';

import { connect } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { useFeatures } from 'reaxl-features';

import adUnits from '@/config/adUnits';

import srpAdsDuck from '@/ducks/srp/srpAdsDuck';

import AtcAdSlot from '@/containers/AtcAdSlot';
import DisableAdsFeatureFlag from '@/containers/global/DisableAdsFeatureFlag';

const backFillSize = [320, 50];

export function FooterLeaderAdSlotContainer({
    adsLazyLoadingOffset,
}) {
    const device = useDevice();

    const {
        SRP_ADS_PLACEHOLDER_OFF: [srpAdsPlaceholderDisabled] = false,
        srp_ads_single_size: [isSingleSizeOnly],
    } = useFeatures(['SRP_ADS_PLACEHOLDER_OFF', 'srp_ads_single_size']);

    // ensure ad sizes are in order from shortest to tallest to make sure min size is smallest height
    const extraSmallSize = isSingleSizeOnly ? [backFillSize] : [backFillSize, [320, 250]];
    const normalSize = [[728, 90]];

    const isXs = _get(device, 'is.xs', false);
    const size = isXs ? extraSmallSize : normalSize;

    const targeting = {
        anml: 'N',
        pos: 'e',
        prd: isXs ? null : 'dlr',
    };

    const path = `${adUnits?.atcFindcar}/srp`;

    return (
        <DisableAdsFeatureFlag>
            <AtcAdSlot
                className="col-xs-12"
                lazyLoading
                targeting={targeting}
                lazyLoadingBottomOffset={adsLazyLoadingOffset}
                lazyLoadingTopOffset={adsLazyLoadingOffset}
                showPlaceholder={!srpAdsPlaceholderDisabled}
                size={size}
                slotId="footerLeader"
                path={path}
            />
        </DisableAdsFeatureFlag>
    );
}

const mapStateToProps = (state) => ({
    adsLazyLoadingOffset: srpAdsDuck.selectors.getAdsLazyLoadingOffset(state),
});

export default connect(mapStateToProps)(FooterLeaderAdSlotContainer);
