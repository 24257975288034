import React, { memo, useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import clsx from 'clsx';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

// atc-ui
import { useDevice } from '@bonnet/next/device';

import { formattedNumber } from 'atc-js';

import {
    Carousel,
    Chip,
} from 'reaxl';
import { useAnalytics } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';

import {
    srpFiltersDuck,
    srpSuggestedFiltersDuck,
} from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

import InventoryFiltersChipsContainer from './InventoryFiltersChipsContainer';
import RecentSearchChipsContainer from './RecentSearchChipsContainer';

function FilterChip({ name, count, codes, onClick }) {
    return (
        <div
            data-cmp="filterChip"
            className="display-flex flex-column"
        >
            <Chip
                label={(
                    <span>
                        <span className="text-gray-base margin-right-1">
                            {name}
                        </span>
                        <span className="text-subdued-lightest">
                            {count && `(${formattedNumber({ value: count })})`}
                        </span>
                    </span>
                )}
                onClick={(event) => onClick(event, codes)}
                uiContext="filter"
            />
        </div>
    );
}

const ChipCarousel = memo(({
    showCarousel,
    showFilterChips,
    suggestedFilters = [],
    showRecentSearches = false,
}) => {
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const isLessThanMd = _get(device, 'lessThan.md', false);

    const navigateToSrp = useSrpNavigation();
    const { sendClick } = useAnalytics();

    const options = useMemo(() => ({
        prevNextButtons: !isXs,
    }), [isXs]);

    const handleChipClick = useCallback(async (event, codes) => {
        const parameters = codes;

        sendClick('suggestedFilterClick', event);

        // will only send query of selected chip, could include multiple filter changes
        const filtersValues = {
            ...parameters,
        };

        navigateToSrp({
            resetPagination: true,
            isNewSearch: true,
            filtersValues,
            action: 'merge',
        });
    }, [navigateToSrp, sendClick]);

    const carouselProps = {
        options,
        initialIndex: 0,
        lazyLoad: 3,
        fullWidth: false,
        fullHeight: true,
        fullWidthScroll: true,
        visibilityThreshold: 1,
        'data-cmp': 'suggestedFilters',
    };

    if (showCarousel) {
        return showRecentSearches ? (
            <Carousel
                {...carouselProps}
            >
                <InventoryFiltersChipsContainer
                    showFallbackMessage={false}
                    showFilterChips={!isLessThanMd}
                    noWrap
                />
                <RecentSearchChipsContainer />
                {suggestedFilters.map((chip) => (
                    <FilterChip
                        key={chip.name}
                        onClick={handleChipClick}
                        {...chip}
                    />
                ))}
            </Carousel>
        ) : (
            <Carousel
                {...carouselProps}
            >
                <InventoryFiltersChipsContainer
                    showFallbackMessage={false}
                    showFilterChips={!isLessThanMd}
                    noWrap
                />
                {suggestedFilters.map((chip) => (
                    <FilterChip
                        key={chip.name}
                        onClick={handleChipClick}
                        {...chip}
                    />
                ))}
            </Carousel>
        );
    }

    if (showFilterChips) {
        return (
            <div
                data-cmp="suggestedFilters"
            >
                <InventoryFiltersChipsContainer
                    carouselProps={carouselProps}
                    showFallbackMessage={false}
                    showFilterChips={!isLessThanMd}
                />
            </div>
        );
    }

    return null;
}, _isEqual);

export function SuggestedFilterContainer() {
    const {
        suggested_filters: [enableSuggestedFilters],
        recent_searches_new_placement: [enableNewRecentSearchesPlacement],
    } = useFeatures([
        'suggested_filters',
        'recent_searches_new_placement',
    ]);

    const suggestedFilters = useSelector(srpSuggestedFiltersDuck.selectors.getSuggestedFilters) || [];
    const showSuggestedFilters = suggestedFilters && Object.keys(suggestedFilters).length > 0;
    const activeFilterValues = useSelector(srpFiltersDuck.selectors.getSelectedFilterChips, _isEqual);
    const showFilterChips = activeFilterValues && Object.keys(activeFilterValues).length > 0;

    const rootClassNames = clsx('flex-1', {
        'margin-vertical-1': !showSuggestedFilters && !showFilterChips,
    });

    return enableSuggestedFilters && (
        <div className={rootClassNames}>
            <ChipCarousel
                showCarousel={!!showSuggestedFilters}
                showFilterChips={!!showFilterChips}
                suggestedFilters={suggestedFilters}
                showRecentSearches={enableNewRecentSearchesPlacement}
            />
        </div>
    );
}

export default React.memo(SuggestedFilterContainer);
