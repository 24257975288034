import React, { memo } from 'react';

import { batch, useSelector } from 'react-redux';

import Error from 'next/error';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { withCtxMiddleware } from '@bonnet/next/ctx-middleware';
import { withCtxAbortOnStatusCode } from '@bonnet/next/ctx-middleware/with-ctx-abort-on-status-code';
import { withBonnetPage } from '@bonnet/next/page';
import { useRouterScroll } from '@bonnet/next/use-router-scroll';

import { pageNames } from '@atc/bonnet-paths';

import { getScaledImageSource } from 'atc-js';

import { StickyContainer } from 'reaxl';
import { AnalyticsProvider } from 'reaxl-analytics';
import { atcAuthModule } from 'reaxl-auth';
import { useFeatures } from 'reaxl-features';

import { imageSizes } from 'axl-config';

import getSRPPrimaryImagesFromCtx from '@/utilities/getSRPPrimaryImagesFromCtx';
import useHashScroll from '@/utilities/useHashScroll';
import usePauseAudioEye from '@/utilities/usePauseAudioEye';

import globalAnalyticsHandlers from '@/analytics/globalAnalyticsHandlers';
import inventoryAnalytics from '@/analytics/inventoryAnalyticsHandlers';
import nativeDealAnalytics from '@/analytics/nativeDealAnalyticsHandlers';
import ownerAnalyticsHandlers from '@/analytics/ownerAnalyticsHandlers';
import psxAnalyticsHandlers from '@/analytics/psxAnalyticsHandlers';
import srpAnalyticsHandlers from '@/analytics/srpAnalyticsHandlers';
import useAnalyticsPageEventListener from '@/analytics/useAnalyticsPageEventListener';
import useDataLayer from '@/analytics/useDataLayer';

import campaignConfigData from '@/config/campaignConfigs';

import {
    addSearchResultsPageData,
    ctxPresetFindCar,
    dispatchSearchResultsPageData,
    withConsumerRatings,
    withCustomLeadRouting,
    withDataIslandDispatcher,
    withInterestRates,
    withPaymentServices,
    withPreloadHeader,
    withSelfServiceSeo,
    withWalletConfigs,
} from '@/context-middleware';

import {
    srpFetchCompositeJSON,
    withBreadcrumbPaths,
    withCampaignCookie,
    withCrawlPath,
    withKbbEditorialFetcher,
    withNoResultsSRP,
    withPrivateSellerRadiusBoost,
    withRequestOptions,
    withSavedRecentSearch,
    withSEOContent,
    withSrpQueryFixes,
} from '@/context-middleware/srp';

import { birfDuck, globalConfigsDuck } from '@/ducks';

import * as srpDucks from '@/ducks/srp';
import { vdpActiveInteractionDuck, vdpDealerDiffDuck, vdpModelInfoDuck, vdpResultsDuck } from '@/ducks/vdp';

import {
    AlphaModule,
    CanonicalUrlModule,
    CookieConsentModule,
    ParsedQueryModule,
    PersonalizationEngineModule,
} from '@/modules';

import { CommonHeadContainer, PersonalizationEngineContainer } from '@/containers';

import SearchResultsPageContainer from '@/containers/srp/SearchResultsPageContainer';
import SrpPageEventsContainer from '@/containers/srp/SrpPageEventsContainer';

import JumpLink from '@/components/JumpLink/index.js';
import SrpError from '@/components/SrpError/index.js';

const { SEARCH_RESULTS } = pageNames;

const SearchResultsPage = memo(({
    openGraphImage,
    currentUrl,
    statusCode,
    showSrpError = false,
    query = {},
    dataLayerProps,
}) => {
    const {
        disable_personalization_engine: [isPersonalizationEngineDisabled],
        disable_thirdparty: [disableThirdParty],
        google_analytics: [, { disableGtm, disableGtmEvents, delay }],
        oneTrust: [isOneTrustEnabled],
        optimizely_edge: [enableOptimizelyEdge],
    } = useFeatures([
        'disable_personalization_engine',
        'disable_thirdparty',
        'google_analytics',
        'oneTrust',
        'optimizely_edge',
    ]);

    const analyticsPageEventListener = useAnalyticsPageEventListener();

    // TODO: BONNET - Can we find a more efficient way to handle prefetching specific routes on specific pages
    // wrapping dynamic import of vdp bundle with falsy check to make the `import()` actually unreachable.  this
    //   should make webpack only add the prefetch of the bundle with a `<link rel="prefetch">` without adding the
    //   corresponding `<script>` tag from actually hitting `import()`.
    // TL;DR this prefetches vdp bundles from srp so that it will be cached (w/o processing yet) and improve SPA transition
    // const VdpPreload = lazy(() => new Promise(() => {
    //     setTimeout(() => import(
    //         /* webpackChunkName: "vdp" */
    //         './VehicleDetailsPage'
    //     ), 20000);
    // }));

    usePauseAudioEye();

    const hasSuppressedCookies = useSelector(CookieConsentModule.duck.selectors.selectHasSuppressedGroup) && isOneTrustEnabled;

    // useRouterScroll will scroll the page back to the top when the route is loaded from
    // a internal or external page transtion to this page
    // UX does not want the page to scroll to the top when the filters are selected and update the route
    // we set that flag state in before we apply filters and read it here to disable the scroll
    const disableScroll = useSelector(srpDucks.srpDisableScrollDuck.selectors.getDuckState);

    useRouterScroll({
        disableScroll,
    });

    // offset of 100 lines up perfectly with sticky header, and also works nicely for desktop breakpoints
    useHashScroll({ offset: -100 });

    const dataLayer = useDataLayer(dataLayerProps);

    const dataIsland = useSelector(birfDuck.selectors.getPageData);
    const analyticsDataIsland = {
        ...dataIsland,
    };
    const telmetricsKey = useSelector(globalConfigsDuck.selectors.getDuckState)?.telmetricsKey;

    const analyticsProps = {
        google: {
            delay,
        },
        disabled: hasSuppressedCookies,
        eventListener: analyticsPageEventListener,
        instanceId: dataLayer?.page?.pixallPageInstanceId,
        dataLayer: () => ({ ...dataLayer }),
        getDataIsland: () => analyticsDataIsland,
        value: {
            ...globalAnalyticsHandlers,
            ...inventoryAnalytics,
            ...ownerAnalyticsHandlers,
            ...psxAnalyticsHandlers,
            ...srpAnalyticsHandlers,
            ...nativeDealAnalytics,
        },
        option: {
            isOptimizelyEdge: enableOptimizelyEdge,
            disableGTM: disableGtm || disableThirdParty,
            disableGTMEvents: disableGtmEvents,
            disablePixall: disableThirdParty,
            telmetricsKey,
        },
    };

    // TODO: BONNET - Prefetch VDP from SRP and vice versa

    // if the srp has error from a fallback api call
    // then show an SrpError that allows the user to try again
    if (showSrpError) {
        return (
            <SrpError />
        );
    }

    if (statusCode) {
        return <Error statusCode={statusCode} />;
    }

    return (
        <>
            {!isPersonalizationEngineDisabled && <PersonalizationEngineContainer pageName={SEARCH_RESULTS} />}
            <CommonHeadContainer
                currentUrl={currentUrl}
                pageName={SEARCH_RESULTS}
                query={query}
                openGraphImage={openGraphImage}
            />
            <AnalyticsProvider {...analyticsProps}>
                <SrpPageEventsContainer />
                <JumpLink
                    message="Skip to vehicle Listings"
                    id="#srp-listings"
                />
                <StickyContainer data-qaid="cntnr-spdysrp">
                    <SearchResultsPageContainer currentUrl={currentUrl} />
                </StickyContainer>
            </AnalyticsProvider>
        </>
    );
}, (prev, next) => _isEqual(prev, next));

SearchResultsPage.getInitialProps = async (ctx) => {
    // Pre-init the srp
    // This really only benefits a subsequent reloading of the page (filter, pagination, etc)
    // TODO: BONNET - We may want to clear out listings, spotlights and alpha here as well
    // and replace with placeholders?
    const actions = [
        vdpModelInfoDuck.creators.clear(),
    ];

    batch(() => {
        actions.forEach((action) => {
            ctx.store.dispatch(action);
        });
    });

    // apply context middleware
    await withCtxMiddleware([
        ctxPresetFindCar({
            pageName: SEARCH_RESULTS,
            preFetchCtxMiddleware: [
                withSrpQueryFixes(),
                withSelfServiceSeo(),
                withCampaignCookie({ data: campaignConfigData }),
                withPrivateSellerRadiusBoost(),
                withRequestOptions(),
                withKbbEditorialFetcher(),
                ctx.withModuleCtxMiddleware({
                    batchDispatch: false,
                    fetchCompositeJSON: false,
                }),
                // AlphaModule.ctxMiddleware,
                withSEOContent(),
                addSearchResultsPageData(),
            ],
            fetcher: srpFetchCompositeJSON,
            dispatcher: dispatchSearchResultsPageData,
        }),
        withNoResultsSRP(),
        withCtxAbortOnStatusCode(),
        withCrawlPath(),
        withBreadcrumbPaths(),
        withInterestRates(),
        withWalletConfigs(),
        withPaymentServices(),
        withConsumerRatings(),
        withCustomLeadRouting(),
        withSavedRecentSearch(),
        withPreloadHeader(),
        withDataIslandDispatcher(),
    ], ctx);

    const totalBoostCount = ctx?.data?.totalBoostCount;

    if (ctx.data.showSrpError) {
        ctx.res?.status(500);
        return {
            showSrpError: ctx.data.showSrpError,
        };
    }

    // if the current ctx contains a status code then we need return it
    if (ctx.statusCode) {
        return {
            statusCode: ctx.statusCode,
        };
    }

    const images = getSRPPrimaryImagesFromCtx(ctx);

    let openGraphImage;
    if (ctx?.data?.listings?.length > 0) {
        const primary = _get(images, 'primary', 0);
        const rawOpenGraphImage = _get(ctx, `data.listings[0].images.sources[${primary}].src`);
        openGraphImage = rawOpenGraphImage && getScaledImageSource({
            src: rawOpenGraphImage,
            ...imageSizes.openGraphImg,
        });
    }

    // Add required value from ctx for useDataLayer
    const dataLayerProps = {
        totalBoostCount,
    };
    return {
        images,
        openGraphImage,
        query: ctx.query,
        currentUrl: ctx.data.currentUrl,
        dataLayerProps,
    };
};

const { BonnetPageComponent } = withBonnetPage(SearchResultsPage, {
    reducers: [
        ...Object.values(srpDucks),
        vdpResultsDuck,
        vdpActiveInteractionDuck,
        vdpDealerDiffDuck,
    ],
    modules: [
        AlphaModule,
        CanonicalUrlModule,
        CookieConsentModule,
        ParsedQueryModule,
        atcAuthModule,
        PersonalizationEngineModule,
    ],
});

export default BonnetPageComponent;
