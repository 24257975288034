import React from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

// alerts containers
import { useDevice } from '@bonnet/core/device';

import { ClientOnly } from '@atc/react-client-only';

import { BrowserStorage } from 'atc-js';

import { SponsoredText } from 'reaxl';
import { useFeatures } from 'reaxl-features';

import {
    cbhVehicleInterestsDuck,
} from '@/ducks';

import {
    srpPrimeSpotlightDuck,
} from '@/ducks/srp';

import BrowserNotificationContainer from '@/containers/BrowserNotificationContainer';
import WalletAlertsContainer from '@/containers/WalletAlertsContainer';

import usePremiumSpotlightUrgencyDrivers from './hooks/usePremiumSpotlightUrgencyDrivers.js';
import SpotlightAlertContainer from './SpotlightAlertContainer';
import StillInterestedAlert from './StillInterestedAlert';

function SrpAlertsContainer() {

    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    const {
        active_deals_my_wallet: [enableActiveDeals],
        offsite_browser_notifications: [, { srp_prompt: enableBrowserNotificationPrompts }],
        show_wallet_alert_notification: [showWalletAlertNotification],
        still_interested_alert: [enableStillInterestedAlert],
        premium_spotlight: [, { top_placement: enableSpotlightAlert }],
    } = useFeatures([
        'active_deals_my_wallet',
        'offsite_browser_notifications',
        'show_wallet_alert_notification',
        'still_interested_alert',
        'premium_spotlight',
    ]);

    // wallet alerts and browser notifcation prompt local storage values
    const walletNotifCache = new BrowserStorage('WALLET_NOTIF_POPOVER', { persist: true });
    const stillInterestedVehicle = useSelector((state) => cbhVehicleInterestsDuck.selectors.getFirstVehicle(state));
    const premiumSpotlightListing = useSelector(srpPrimeSpotlightDuck.selectors.getActiveInventory, _isEqual)[0];
    const alertEnabled = (showWalletAlertNotification && enableActiveDeals);
    const showWalletAlert = alertEnabled && walletNotifCache.getItem()?.show;
    const showStillInterested = enableStillInterestedAlert && stillInterestedVehicle;

    const premiumSpotlightUrgencyDriver = usePremiumSpotlightUrgencyDrivers({ spotlight: premiumSpotlightListing });

    return (
        <ClientOnly>
            {enableBrowserNotificationPrompts && (<BrowserNotificationContainer />)}

            {(showWalletAlert && !isXs) && (
                <WalletAlertsContainer enableAlert={showWalletAlert} />
            )}
            {(!showWalletAlert && showStillInterested) && (
                <StillInterestedAlert
                    stillInterestedVehicle={stillInterestedVehicle}
                />
            )}
            {(!showWalletAlert && !showStillInterested && enableSpotlightAlert && premiumSpotlightListing?.id && premiumSpotlightUrgencyDriver?.type) && (
                <>
                    <div
                        key="premiumSpotlightSponsoredText"
                        className="margin-bottom-2 margin-top-2 text-size-100 text-sm-left"
                    >
                        <SponsoredText
                            height={10}
                            width={52}
                        />
                    </div>
                    <SpotlightAlertContainer
                        premiumSpotlightListing={premiumSpotlightListing}
                        displayMultipleImages
                    />
                </>
            )}

        </ClientOnly>
    );
}

export default SrpAlertsContainer;
