import React from 'react';

import { connect } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { useFeatures } from 'reaxl-features';

import adUnits from '@/config/adUnits';

import { globalConfigsDuck } from '@/ducks';

import { srpAdsDuck } from '@/ducks/srp';

import AtcAdSlot from '@/containers/AtcAdSlot';
import DisableAdsFeatureFlag from '@/containers/global/DisableAdsFeatureFlag';

function SRLTowerAdSlotContainer({
    adsLazyLoadingOffset,
    isKbbBranded = false,
    greaterThanMd,
}) {
    const device = useDevice();
    const hideAd = _get(device, 'lessThan.md', false);

    const {
        SRP_ADS_PLACEHOLDER_OFF: [isSRPAdsPlaceholderOff],
    } = useFeatures(['SRP_ADS_PLACEHOLDER_OFF']);

    if (hideAd) {
        return null;
    }

    const path = `${adUnits?.atcFindcar}/srp`;

    const renderSRLTowerAd = () => {
        const slotId = !isKbbBranded ? 'SRLTower' : 'kbbAdsSrlTower';
        // ensure ad sizes are in order from shortest to tallest to make sure min size is smallest height
        // Remove 300x600 on ATC-SRP and KBB-SRP - only desktop
        const size = greaterThanMd ? [[300, 250], [160, 600]] : [[160, 600]];

        const targeting = {
            anml: 'N',
            pos: !isKbbBranded ? 'g' : 'G',
            prd: 'dlr',
        };

        return (
            <DisableAdsFeatureFlag>
                {/* TODO: move styling to atc-ui */}
                <div style={{ position: 'sticky', top: '6rem' }}>
                    <AtcAdSlot
                        path={path}
                        showPlaceholder={!isSRPAdsPlaceholderOff}
                        size={size}
                        slotId={slotId}
                        targeting={targeting}
                        lazyLoading
                        lazyLoadingBottomOffset={adsLazyLoadingOffset}
                        lazyLoadingTopOffset={adsLazyLoadingOffset}
                    />
                </div>
            </DisableAdsFeatureFlag>
        );
    };

    return renderSRLTowerAd();
}

const mapStateToProps = (state) => {
    const adsLazyLoadingOffset = srpAdsDuck.selectors.getAdsLazyLoadingOffset(state);
    const isKbbBranded = globalConfigsDuck.selectors.isKbb(state);
    const greaterThanMd = _get(state, 'browser.greaterThan.md', false);

    return {
        adsLazyLoadingOffset,
        isKbbBranded,
        greaterThanMd,
    };
};

export default connect(mapStateToProps)(SRLTowerAdSlotContainer);
