import React, { memo, useCallback } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import { useDevice } from '@bonnet/next/device';
import { usePageEvent } from '@bonnet/next/use-page-event';

import { mapDispatchToActionProp } from '@atc/modular-redux';

import { formattedNumber } from 'atc-js';

import { SponsoredText } from 'reaxl';
import { sendClick, sendImpressions } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import { InventoryListingPlaceholder } from 'reaxl-listing';

// Utilities
import { getDealerDetailUrl } from '@/utilities/ddpUrlBuilder';
import { saveAlphaListing as saveAlphaListingAction } from '@/utilities/saveListing';
import vdpUrlBuilder from '@/utilities/vdpUrlBuilder';

import {
    birfDuck,
    compareListingsDuck,
    paymentsDuck,
    savedInventoryDuck,
    userPreferencesDuck,
} from '@/ducks';

import {
    srpActiveEmailListingDuck,
    srpActiveInteractionDuck,
    srpSponsorshipDuck,
} from '@/ducks/srp';

// Fetcher

import PaymentCalculationFetcher from '@/fetchers/PaymentCalculationFetcher';

import useVdpNavigation from '@/hooks/useVdpNavigation';

import AlphaModule from '@/modules/AlphaModule';

import AlphaShowcase from '@/components/AlphaShowcase';

import AlphaHandlers from './analytics/AlphaHandlers';

/*
 * The AlphaShowcaseContainer will display either the InventoryListingAlpha or OwnerAlphaListing from reaxl-organisms
 * based on the data it receives.
 */
function AlphaShowcaseContainerComponent({
    actions = {},
    birf,
}) {
    const parentId = 'alp';
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const {
        alpha_showcase: [, { owner_inventory_handler: enableOwnerInventoryHandler }],
        SPONSORSHIP_RESULTS_ONLY: [sponsorshipResultsOnly],
        ddp_link: [isDdpLinkEnabled],
        nds: ndsFeature,
        disable_retail_engine: [disableRetailEngine],
        my_wallet: [enableMyWallet],
        personalization: [isPersonalizationEnabled, { listings_saved: authenticatedSavesOnly }],
    } = useFeatures([
        'alpha_showcase',
        'SPONSORSHIP_RESULTS_ONLY',
        'ddp_link',
        'nds',
        'disable_retail_engine',
        'my_wallet',
        'personalization',
    ]);

    const {
        saveAlphaListing,
        setActiveEmailListingResults,
        setActiveInteraction,
        setUserPreferencesDuck,
        updateMyWallet,
        updateListingPayments,
        addListingToCompare = () => { },
        removeCompareListing = () => { },
    } = actions;

    const { onPageEnter } = usePageEvent('alphaShowcase');
    const navigateToVdp = useVdpNavigation();
    const dispatch = useDispatch();

    const compareToggle = useSelector(userPreferencesDuck.selectors.getCompareToggle);
    const showAlphaWalletPayment = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'paymentEmphasis'));
    const compareListingIds = useSelector(compareListingsDuck.selectors.getActiveListingIds);
    const isSponsorshipSelected = useSelector(srpSponsorshipDuck.selectors.getDuckState);
    const savedListingIds = useSelector(savedInventoryDuck.selectors.getSavedInventoryIds);
    const isOwnerAlpha = !useSelector(AlphaModule.duck.selectors.isListingAlpha);
    const isLoadingAlpha = useSelector(AlphaModule.duck.selectors.isLoading);
    const similarListingsCount = useSelector(AlphaModule.duck.selectors.getSimilarListingsCount);
    const carouselListings = useSelector(AlphaModule.duck.selectors.getActiveInventory, (oldVal, newVal) => oldVal[0]?.id === newVal[0]?.id);
    const showcaseListing = carouselListings[0];
    const owner = useSelector(AlphaModule.duck.selectors.getOwner, (oldVal, newVal) => oldVal.id === newVal.id);
    const hasAlpha = !!owner && owner.name;

    /* default values */
    const dealerDetailUrl = _get(getDealerDetailUrl(owner), 'href', '');
    const listingId = _get(showcaseListing, 'id', '');
    const ownerId = _get(owner, 'id', '');
    const vdpWebsite = vdpUrlBuilder({ baseUrl: _get(showcaseListing, 'vdpBaseUrl'), clickType: 'alpha' });
    const alphaTitle = (`You might like these vehicles from ${owner.name}`);

    /* ui context props*/
    const alphaContext = 'inventory';
    const uiContext = isOwnerAlpha ? 'owner' : alphaContext;

    const orientation = isXs ? 'portrait' : 'landscape';
    const ownerHasLocation = Object.keys(_get(owner, 'location', {})).length > 0;

    /* on initial page load & when the page instance id changes, fire an impression */
    onPageEnter(() => {
        if (ownerId) {
            sendImpressions({
                name: 'alphaImpression',
            });
        }
    });

    /*
     * import alpha analytics handlers
     */
    const handleAlphaWalletClick = AlphaHandlers.useAlphaWalletClick(sendClick, showcaseListing, parentId, updateMyWallet);

    const handleCollapsibleHeaderClick = AlphaHandlers.useCollapsibleHeaderClick(sendClick, showcaseListing);

    const handleGetDirectionsClick = AlphaHandlers.useGetDirectionsClick(sendClick, showcaseListing, owner, ownerId, parentId);

    const handleImageClick = AlphaHandlers.useImageClick(sendClick, showcaseListing, parentId, isOwnerAlpha, navigateToVdp, vdpWebsite, dispatch);

    const handleOwnerInventoryClick = AlphaHandlers.useOwnerInventoryClick(sendClick, showcaseListing, ownerId, enableOwnerInventoryHandler, dealerDetailUrl);

    const handleViewPaymentDetailsClick = AlphaHandlers.useViewPaymentDetailsClick(sendClick, showcaseListing, parentId);

    const handlePaymentCTAClick = AlphaHandlers.usePaymentCTAClick(sendClick, showcaseListing, parentId, updateMyWallet);

    const handlePricingDetailsClick = AlphaHandlers.usePricingDetailsClick(showcaseListing, navigateToVdp, vdpWebsite);

    const handleTileClick = AlphaHandlers.useTileClick(sendClick, showcaseListing, ownerId, parentId);

    const handleInventoryListingClick = AlphaHandlers.useInventoryListingClick(sendClick, showcaseListing, parentId, navigateToVdp, carouselListings, dispatch);

    const handleInViewCarouselChange = AlphaHandlers.useInViewCarouselChange(sendImpressions, carouselListings);

    const handleReadyCallback = AlphaHandlers.useOnCarouselLoad(sendImpressions, carouselListings);

    const isCompared = (listing) => compareListingIds && compareListingIds.includes(listing?.id);
    const handleCompareListingsClick = useCallback((listing) => async (event) => {
        sendClick('compareListingsDrawerClick', event, {
            clickType: 'alpha',
            inventoryId: listing?.id,
            inheritPageData: false,
            par: 'comp_v_add',
            pixallData: {},
        });

        setUserPreferencesDuck({ showDrawer: true });
        addListingToCompare(listing?.id);
        if (compareListingIds && compareListingIds.includes(listing.id)) {
            removeCompareListing(listing?.id);
        }
    }, [setUserPreferencesDuck, addListingToCompare, removeCompareListing, compareListingIds]);

    const handleEmailOwnerClick = useCallback(() => {
        setActiveEmailListingResults([listingId]);
        setActiveInteraction({
            ownerId: uiContext === 'owner' ? ownerId : '',
            parentId,
            showEmailModal: true,
            clickType: 'alpha',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listingId, ownerId, setActiveEmailListingResults, setActiveInteraction, uiContext]);

    const handleTelMetric = useCallback((phoneNumber) => {
        const data = {
            birf,
            listing: showcaseListing,
            phoneNumber,
        };
        sendClick('telMetricsPhoneTextClick', {}, data);
    }, [showcaseListing, birf]);

    // Only show the placeholder when filtering if there is an existing alpha to preserve space for.
    if (isLoadingAlpha && hasAlpha) {
        return (
            <>
                <div
                    key="alphaShowcaseSponsoredText"
                    className="margin-bottom-2 text-size-100 text-right text-sm-left"
                >
                    <SponsoredText
                        height={10}
                        width={52}
                    />
                </div>
                <p
                    key="alphaShowcaseTitle"
                    className="row text-size-300 text-bold padding-bottom-3 margin-vertical-0"
                >
                    <span className="col-xs-12">
                        {alphaTitle}
                    </span>
                </p>
                <div
                    key="alphaShowcaseBody"
                    className="cursor-pointer"
                >
                    <InventoryListingPlaceholder
                        key="alpha-showcase-placeholder"
                        orientation={orientation}
                    />
                </div>
            </>
        );
    }

    /* if there is no alpha owner do no set up rest of the component or
     * if there is a sponsorship takeover active and the sponsorship query param is populated, then return nothing */
    if (!owner || (sponsorshipResultsOnly && isSponsorshipSelected)) {
        return null;
    }

    let textBody;

    if (showcaseListing && Object.keys(showcaseListing).length) {
        const {
            title = '',
            pricingDetail: { salePrice = '', msrp = '' } = {},
        } = showcaseListing;

        const priceText = salePrice || msrp ? ' for ' + formattedNumber({
            value: salePrice || msrp,
            style: 'currency',
            currency: 'USD',
        }) : '';

        textBody = `Hi, I'm interested in your ${title} listed on Autotrader${priceText}`;
    }

    const ownerCTA = {
        textBody: textBody || '',
    };

    const personalPaymentProps = {
        fetcher: PaymentCalculationFetcher,
        paymentsEnabled: !disableRetailEngine,
        ndsFeature,
    };

    const onSaveToggle = (event, listing, wasSaved) => {
        saveAlphaListing({
            event,
            listing,
            wasSaved,
            sendClick,
        });
    };

    /* call to actions */
    const listingCTA = { href: vdpWebsite };

    const paymentsCTA = { onClick: handleViewPaymentDetailsClick };

    const showCompareButton = compareToggle && compareListingIds?.length < 5;

    /* only render alpha if there is an owner object */
    return hasAlpha ? (
        <AlphaShowcase
            data-qaid="cntnr-alpha"
            enableMyWallet={enableMyWallet}
            emailOwnerProps={{
                parentId,
                onCTAClick: handleEmailOwnerClick,
            }}
            lazyLoad={false}
            listing={showcaseListing || {}}
            listings={carouselListings}
            listingsCount={similarListingsCount > 0 ? similarListingsCount : null}
            listingCTA={listingCTA}
            phoneClickHandler={handleTelMetric}
            onAlphaWalletClick={handleAlphaWalletClick}
            onCollapsibleHeaderClick={handleCollapsibleHeaderClick}
            onGetDirectionsClick={handleGetDirectionsClick}
            onImageClick={handleImageClick}
            onInventoryListingClick={handleInventoryListingClick}
            onSettle={handleInViewCarouselChange}
            onPricingDetailClick={handlePricingDetailsClick}
            onSaveToggle={isPersonalizationEnabled || authenticatedSavesOnly ? onSaveToggle : null}
            showCompareButton={showCompareButton}
            onCompareClick={handleCompareListingsClick}
            isCompared={isCompared}
            onTileClick={handleTileClick}
            onViewOwnerInventoryClick={isDdpLinkEnabled && ownerHasLocation && handleOwnerInventoryClick}
            owner={owner}
            ownerTxtCTA={ownerCTA}
            orientation={orientation}
            parentId={parentId}
            paymentsCTA={paymentsCTA}
            personalPaymentProps={personalPaymentProps}
            savedListingIds={savedListingIds}
            showAlphaPaymentRibbon={!!enableMyWallet && !!showAlphaWalletPayment}
            title={alphaTitle}
            uiContext={uiContext}
            updateListingPayments={updateListingPayments}
            carouselSettings={{
                onReady: handleReadyCallback,
                fullWidthScroll: true,
                visibilityThreshold: 0.9,
            }}
            onPaymentCTAClick={handlePaymentCTAClick}
        />
    ) : null;
}

export const AlphaShowcaseContainer = memo(AlphaShowcaseContainerComponent, (newProps, oldProps) => _isEqual(newProps.carouselListingIds, oldProps.carouselListingIds));

function mapStateToProps(state) {
    return {
        birf: birfDuck.selectors.getPageBirf(state),
        carouselListingIds: AlphaModule.duck.selectors.getActiveResults(state),
    };
}

const mapDispatchToProps = mapDispatchToActionProp({
    addListingToCompare: compareListingsDuck.creators.addToCompare,
    saveAlphaListing: saveAlphaListingAction,
    setActiveEmailListingResults: srpActiveEmailListingDuck.creators.setActiveResults,
    setActiveInteraction: srpActiveInteractionDuck.creators.setKeys,
    setUserPreferencesDuck: userPreferencesDuck.creators.setKeys,
    updateMyWallet: paymentsDuck.creators.updateMyWallet,
    updateListingPayments: paymentsDuck.creators.updateListingPayments,
    removeCompareListing: compareListingsDuck.creators.removeFromCompare,
});

export default connect(mapStateToProps, mapDispatchToProps)(AlphaShowcaseContainer);
