import React, { memo, useCallback, useEffect } from 'react';

import clsx from 'clsx';
import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import {
    Carousel,
    Glyphicon,
    SponsoredText,
    Text,
} from 'reaxl';
import { useAnalytics } from 'reaxl-analytics';
import { InventoryListingV2, PromoCard } from 'reaxl-listing';

const eventType = {
    incentive: 'incentives',
    reduced: 'price-reduced',
    newlylisted: 'newly-listed',
    numviewed: 'number-viewed',
};

function PremiumSpotlightContainer({
    premiumSpotlight,
    urgencyDriverData,
    getListingProps,
    listingIndex,
}) {

    const { sendImpressions } = useAnalytics();

    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    const sendListingImpression = useCallback(() => {
        sendImpressions({
            name: 'inventoryImpressions',
            data: {
                cmp: eventType[urgencyDriverData.type],
                selectedItemIds: [premiumSpotlight.id],
            },
        });
    }, [premiumSpotlight.id, sendImpressions, urgencyDriverData.type]);

    useEffect(() => {
        if (premiumSpotlight) {
            sendListingImpression();
        }
    }, [premiumSpotlight, premiumSpotlight.id, sendImpressions, sendListingImpression]);

    const renderContent = () => (
        <>
            <div
                className={clsx({ 'margin-left-5': isXs }, 'row col-sm-6 col-xs-12 align-self-center')}
                style={{ minWidth: isXs ? '70%' : 'inherit' }}
            >
                <PromoCard
                    data-cmp="spotlightPromoCard"
                    className={clsx({ 'margin-left-5': isXs }, 'align-self-center')}
                    bodyClassName={clsx({ 'padding-left-5': isXs })}
                    uiContext="spotlight"
                    {...urgencyDriverData}
                >
                    {isXs && (
                        <div className="margin-top-4">
                            <Text size={200}>
                                See vehicle
                            </Text>
                            <Glyphicon glyph="chevron-right" />
                        </div>
                    )}
                </PromoCard>
            </div>

            <div
                className={clsx({ 'padding-right-5': isXs }, 'col-sm-6 col-xs-12 align-self-center text-left padding-vertical-4')}
                style={{ minWidth: isXs ? '70%' : 'inherit', height: '100%' }}
            >
                <InventoryListingV2
                    style={{ height: '100%' }}
                    uiContext="simplified"
                    {...getListingProps('peekaboo', premiumSpotlight, listingIndex)}
                />
            </div>
        </>
    );

    const renderCarousel = () => (
        <Carousel
            className="padding-left-4"
            initialIndex={0}
            fullHeight
            fullWidth={false}
            onStaticClick={() => { }}
            onSettle={() => { }}
            onNextButtonClick={() => { }}
            onPrevButtonClick={() => { }}
            lazyLoad={false}
            prevNextButtons={false}
            fullWidthScroll
            visibilityThreshold={0.9}
        >
            {renderContent()}
        </Carousel>
    );

    return (
        <div className="col-sm-8 col-xs-12 display-flex flex-column">
            <div
                className="display-block text-right"
                style={{ position: 'absolute', top: -20 }}
            >
                <SponsoredText />
            </div>
            <div
                data-cmp="PremiumSpotlightContainer"
                key="PremiumSpotlightContainer"
                className="panel panel-default bg-periwinkle display-flex justify-content-around align-items-center flex-grow"
            >
                {isXs ? renderCarousel() : renderContent()}
            </div>
        </div>
    );
}

export default memo(PremiumSpotlightContainer);
