import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import MaskedTextInput from 'react-text-mask';

import _get from 'lodash/get';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { eventBus } from '@atc/cai-event-bus';

import { calculateDownPayment, formatCurrency, getInterestRate, getObfuscatedCredit } from 'atc-js';

import { Badge, Button, Col, DebouncedInput, Glyphicon, Image, Label, LinkIcon, LoadingSpinner, PositionedOverlay, PositionedOverlayAnchor, PositionedOverlayBase, Row, Select, Text } from 'reaxl';
import { DelayedImpressionObserver, useAnalytics } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
// TODO: will be added back in when converted to a label
// import { SyncMyWalletAlert } from 'reaxl-wallet';

import { fallbackInterestRates, ratesLabels } from 'axl-config';

import {
    WALLET_CREATE_ID_REQUEST,
} from 'wallet-manager';

import { paymentsDuck } from '@/ducks';

import { srpReuseActiveStateDuck } from '@/ducks/srp';

import PaymentCalculationFetcher from '@/fetchers/PaymentCalculationFetcher';

import useSrpNavigation from '@/hooks/useSrpNavigation';

const currencyMask = createNumberMask({
    prefix: '$',
    pattern: '[0-9,$]{1,10}',
    type: 'money',
    includeThousandsSeparator: true,
    allowDecimal: false,
    requireDecimal: false,
    allowLeadingZeroes: false,
});

// using this version of credit tier options to remove range and save space
// TODO: after test use axl-config to get labels, either use labels with range or
// add this abbreviated version to ratesLabels
const getCreditTierOptions = (nationalRates) => {
    const ratesClone = { ...nationalRates };
    delete ratesClone.isFallback;

    const creditTierLabelKey = {
        excellent: 'Excellent',
        verygood: 'Very Good ',
        good: 'Good',
        fair: 'Fair',
        rebuilding: 'Rebuilding',
    };
    return Object.keys(ratesClone).reduce((acc, key) => {
        acc.push({
            label: creditTierLabelKey[key],
            value: key,
        });
        return acc;
    }, []);
};

function TradeInComponent({ children, onCtaClick, localValue, onInputChange, tradeIn }) {
    const tradeInValue = tradeIn.value;
    return (
        !tradeInValue
            ? (
                <div className="rounded border-gray-light padding-2 padding-sm-3 ">
                    <div className="display-flex justify-content-center align-items-center text-left gap-2">
                        <Image
                            alt=""
                            height={25}
                            src="/content/static/img/icon/logos/kbb-logo-notext.svg"
                            width={20}
                        />
                        <Button
                            bsStyle="text"
                            className="text-size-200 line-height-sm"
                            onClick={onCtaClick}
                        >
                            Get My Kelley Blue Book&#174; Car Value
                        </Button>
                    </div>
                    {children}
                </div>
            )
            : (
                <>
                    <DebouncedInput
                        label="Trade-In Value"
                        component={MaskedTextInput}
                        inputMode="numeric"
                        onChange={onInputChange}
                        onChangeDuration={800}
                        mask={currencyMask}
                        layout="inside"
                        value={localValue}
                    />
                    {children}
                </>
            )
    );

}

const fetchTotalPayment = async ({ paymentsInfo, paymentCalculationFetcher }) => {
    const { loanTerm, monthlyBudget = 0, tradeIn, interestRate, downPayment, downPaymentPercent } = paymentsInfo;
    const tradeInParam = tradeIn?.value || 0;
    const amountOwedParam = tradeIn?.amountOwed || 0;
    const interestRateParam = getInterestRate(interestRate);
    const downPaymentParam = downPayment
        ? `downPayment=${downPayment}`
        : `downPaymentPercent=${downPaymentPercent * 100}`;

    const budgetApiParams = `monthlyPayment=${monthlyBudget}&tradeInValue=${tradeInParam}&amountOwed=${amountOwedParam}&loanTerm=${loanTerm}&interestRate=${interestRateParam}&${downPaymentParam}`;
    const { totalBudget } = (await paymentCalculationFetcher(budgetApiParams, true)) || {};

    return totalBudget;
};

// mapping to inputsMap on walletEvents.js
const inputsMap = {
    creditTier: 'creditType',
    downPayment: 'downPaymentAmount',
    monthlyBudget: 'budgetAmountInput',
    tradeIn: 'tradeInValue',
};

function SrpWalletGridInputs() {
    const {
        disable_ico: [disableIco],
        wallet_price_filter: [, walletPriceFilterVariants],
    } = useFeatures(['disable_ico', 'wallet_price_filter']);

    const { sendClick, sendImpressions } = useAnalytics();

    const dispatch = useDispatch();
    const navigateToSrp = useSrpNavigation();

    const displayModal = useSelector(paymentsDuck.selectors.getDisplayMyWalletModal);

    const [isSavingChanges, setIsSavingChanges] = useState(false);

    const updateMyWalletInteractions = useCallback((data) => dispatch(paymentsDuck.creators.updateMyWalletInteractions(data)), [dispatch]);
    const hasOpenedTradeIn = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'hasOpenedTradeIn'));
    const updateMyWallet = (data) => dispatch(paymentsDuck.creators.updateMyWallet(data));

    const downPayment = useSelector((state) => paymentsDuck.selectors.getDownPayment(state));
    const downPaymentPercent = useSelector((state) => paymentsDuck.selectors.getDownPaymentPercent(state));

    const creditType = useSelector(paymentsDuck.selectors.getCreditType);

    const decodedCreditType = ratesLabels[creditType]?.decodedValue;

    const nationalRates = useSelector((state) => state?.interestRates || fallbackInterestRates);

    const creditTierOptions = getCreditTierOptions(nationalRates);

    const { v1_displayValue: defaultMaxPayment } = walletPriceFilterVariants;
    const budget = useSelector((state) => paymentsDuck.selectors.getBudget(state));
    const monthlyBudget = useSelector((state) => paymentsDuck.selectors.getMonthlyBudget(state));

    const isPaymentsInfoLoaded = useSelector(paymentsDuck.selectors.getIsPaymentsInfoLoaded);
    const [localDownPayment, setLocalDownPayment] = useState();
    const [defaultBudgetValue, setDefaultBudgetValue] = useState('');
    const displayBudgetValue = budget || defaultBudgetValue;

    const localMonthlyPayment = monthlyBudget || defaultMaxPayment;

    const syncAlert = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'syncAlert'));
    const showAlert = syncAlert?.showAlert;

    const walletId = useSelector((state) => paymentsDuck.selectors.getWalletId(state));

    const tradeIn = useSelector((state) => paymentsDuck.selectors.getTradeIn(state));
    const { amountOwed, amountOwedSource, ico = [], hasNewTradeIn, value: tradeInValue } = tradeIn;

    const loanTerm = useSelector((state) => paymentsDuck.selectors.getLoanTerm(state));

    const interestRates = useSelector((state) => paymentsDuck.selectors.getInterestRates(state));
    const defaultInterestRate = getInterestRate(interestRates);

    const getInterestRates = (type, term) => _get(nationalRates, `${type}.rates.${term}`, {});

    const icoEnabled = !disableIco;
    const showBadge = (icoEnabled && !!ico.length && !hasOpenedTradeIn) || hasNewTradeIn;

    const findOrCreateWalletId = () => {
        if (!walletId) {
            eventBus.emit(WALLET_CREATE_ID_REQUEST);
        }
    };

    const handleInputUpdate = useCallback((event, inputType) => {
        event.persist();
        const { value } = event.target;

        findOrCreateWalletId();

        const sanitizedValue = value.replace(/\D/g, '');

        const getUpdatedData = () => {
            if (inputType === 'tradeIn') {

                const tradeInDetails = {
                    amountOwed,
                    amountOwedSource,
                    source: 'USER',
                    ico,
                };

                return {
                    tradeIn: {
                        ...tradeInDetails,
                        value: Number(sanitizedValue),
                    },
                };
            }

            if (inputType === 'downPayment') {
                return {
                    downPaymentPercent: null,
                    downPayment: Number(sanitizedValue),
                };
            }

            if (inputType === 'monthlyBudget') {
                return {
                    monthlyBudget: Number(sanitizedValue),
                };
            }

            if (inputType === 'creditTier') {
                const obfuscatedCredit = getObfuscatedCredit(value);
                const newRates = getInterestRates(value, loanTerm);

                dispatch(paymentsDuck.creators.updatePaymentsInfo({
                    creditType: obfuscatedCredit,
                    interestRate: newRates,
                }));

            }

            return {};
        };

        const updatedWalletData = getUpdatedData();

        dispatch(paymentsDuck.creators.updatePaymentsInfo({
            ...updatedWalletData,
            interactions: {
                hasUpdatedWallet: true,
            },
        }));

        setIsSavingChanges(true);

        setTimeout(() => {
            setIsSavingChanges(false);
        }, 1700);
        const extraData = inputType === 'creditTier' && ` - ${value}${ratesLabels[value]?.subLabel?.replace(/\s/g, '')}`;

        sendClick('myWalletInputChange', null, {
            inputName: inputsMap[inputType],
            walletPromoCardInput: true,
            ...(extraData && { extraData }),
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amountOwed, amountOwedSource, dispatch, ico, loanTerm, creditType, decodedCreditType]);

    const handleSearchAvailVehiclesClick = async (maxPrice) => {
        sendClick('searchAvailableVehiclesClick', null, {
            isWalletPromoCard: true,
        });

        const newFiltersValues = {
            maxPrice,
        };

        await dispatch(srpReuseActiveStateDuck.creators.setFalse());
        navigateToSrp({
            filtersValues: newFiltersValues,
            isNewSearch: true,
            resetPagination: true,
        });
    };

    const onSearchVehiclesCtaClick = () => handleSearchAvailVehiclesClick(budget);

    const calculateDefaultBudget = async () => {
        const localPaymentsInfo = {
            loanTerm,
            monthlyBudget: localMonthlyPayment,
            tradeIn,
            interestRate: interestRates,
            downPayment,
            downPaymentPercent,
        };

        const calculatedBudget = await fetchTotalPayment({ paymentsInfo: localPaymentsInfo, paymentCalculationFetcher: PaymentCalculationFetcher });

        setDefaultBudgetValue(calculatedBudget);
    };

    const calculateDisplayDownPayment = (budgetValue) => {

        const calculatedDownPayment = calculateDownPayment(budgetValue, { downPaymentPercent, downPayment });

        setLocalDownPayment(calculatedDownPayment);
    };

    const calculateInitialValues = async () => {

        await calculateDefaultBudget();

        calculateDisplayDownPayment(defaultBudgetValue);
    };

    useEffect(() => {
        if (isPaymentsInfoLoaded && (!downPayment && !budget)) {
            calculateInitialValues();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaymentsInfoLoaded, displayBudgetValue]);

    // recalculate display down payment when budget is adjusted
    useEffect(() => {
        if (!downPayment && budget) {

            calculateDisplayDownPayment(budget);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budget]);

    const onTradeInCtaClick = () => {
        sendClick('myWalletTradeInClickLink', null, {
            isWalletPromoCard: true,
        });

        findOrCreateWalletId();

        if (!hasOpenedTradeIn) {
            updateMyWalletInteractions({ hasOpenedTradeIn: true });
        }

        updateMyWallet({
            displayModal: !displayModal,
            selectedPanelName: 'kbbValuation',
        });
    };

    const onKbbValuationClick = (e) => {
        findOrCreateWalletId();
        sendClick('walletPromoCardLinkClick', null, {
            text: `srp::${e.target.innerText}`,
        });
        updateMyWallet({
            displayModal: !displayModal,
            selectedPanelName: 'valuationHistory',
        });
    };

    const onUpdateSettingsClick = (e) => {
        sendClick('walletPromoCardLinkClick', null, {
            text: e.target.innerText,
        });
        updateMyWallet({
            displayModal: !displayModal,
            selectedPanelName: 'budget',
        });
    };

    const handleMonthlyPaymentChange = (e) => {
        handleInputUpdate(e, 'monthlyBudget');
    };

    const handleCreditTierChange = (e) => {
        handleInputUpdate(e, 'creditTier');
    };

    const handleDownPaymentChange = (e) => {
        handleInputUpdate(e, 'downPayment');
    };

    const handleTradeInChange = (e) => {
        handleInputUpdate(e, 'tradeIn');
    };

    const handleTradeInImpression = () => {
        sendImpressions({
            name: 'myWalletGridTradeInIndicator',
            data: {
                isWalletPromoCard: true,
            },
        });
    };

    return (
        <div
            className="margin-top-2"
            data-cmp="myWalletGridInputs"
        >
            <Text
                color="primary"
                componentClass="div"
                size={500}
                weight="bold"
            >
                Find Your Vehicle Faster
            </Text>
            <Text
                className="margin-bottom-3"
                color="accent"
                componentClass="div"
                size={500}
                weight="bold"
            >
                and Within Budget
            </Text>
            <Row>
                <Col
                    xs={6}
                    sm={12}
                >
                    <DebouncedInput
                        label="Monthly Payment"
                        component={MaskedTextInput}
                        inputMode="numeric"
                        layout="inside"
                        mask={currencyMask}
                        onChange={handleMonthlyPaymentChange}
                        onChangeDuration={800}
                        value={localMonthlyPayment}
                    />
                </Col>
                <Col
                    xs={6}
                    sm={12}
                >
                    <Select
                        id="creditTypeSelect-wallet"
                        labelProps={{
                            label: 'Credit & Term',
                        }}
                        options={creditTierOptions}
                        onChange={handleCreditTierChange}
                        value={decodedCreditType}
                        layout="inside"
                    />
                </Col>
            </Row>
            <Row>
                <Col
                    xs={6}
                    sm={12}
                >
                    <DebouncedInput
                        label="Down Payment"
                        component={MaskedTextInput}
                        inputMode="numeric"
                        layout="inside"
                        mask={currencyMask}
                        onChange={handleDownPaymentChange}
                        onChangeDuration={800}
                        value={downPayment || localDownPayment}
                    />

                </Col>
                <Col
                    xs={6}
                    sm={12}
                >
                    <PositionedOverlay shrinkwrap={false}>
                        <PositionedOverlayBase key="tradeInBadgeOverlayBase">
                            <TradeInComponent
                                onCtaClick={onTradeInCtaClick}
                                localValue={tradeInValue}
                                onInputChange={handleTradeInChange}
                                tradeIn={tradeIn}
                            >
                                <PositionedOverlayAnchor
                                    key="tradeInOverlayAnchor"
                                    offsetRight={50}
                                    offsetTop={25}
                                    position="topRight"
                                >
                                    {showBadge && (
                                        <DelayedImpressionObserver
                                            onChange={handleTradeInImpression}
                                            delay={0}
                                        >
                                            <Badge circle>1</Badge>
                                        </DelayedImpressionObserver>
                                    )}
                                </PositionedOverlayAnchor>
                            </TradeInComponent>
                        </PositionedOverlayBase>
                    </PositionedOverlay>
                </Col>
            </Row>

            {
                hasNewTradeIn && (
                    <Button
                        bsStyle="text"
                        className="text-size-200"
                        onClick={onKbbValuationClick}
                    >
                        Apply an existing Kelley Blue Book trade-in value
                    </Button>
                )
            }
            {/* setting height to prevent layout shift */}
            <div style={{ height: '87px' }}>
                {isSavingChanges
                    ? <LoadingSpinner className="padding-top-3 margin-top-1" />
                    : (
                        <div className="margin-bottom-3 ">
                            <Text
                                className="margin-top-1"
                                color="primary"
                                componentClass="div"
                                size={700}
                                weight="ultra-bold"
                            >
                                {formatCurrency(displayBudgetValue)}
                            </Text>
                            <Text
                                color="subdued"
                                size={200}
                            >
                                Budget based on
                                {' '}
                                {defaultInterestRate}
                                % APR at
                                {' '}
                                {loanTerm}
                                {' '}
                                mos.
                            </Text>

                            <LinkIcon
                                className="text-size-200 display-block"
                                glyph="edit"
                                placement="before"
                                onClick={onUpdateSettingsClick}
                            >
                                Update Settings
                            </LinkIcon>

                        </div>
                    )}
            </div>

            <Button
                block
                bsStyle="primary"
                className="margin-top-2"
                disabled={isSavingChanges}
                onClick={onSearchVehiclesCtaClick}
                type="submit"
            >
                Search Available Vehicles
            </Button>

            {/* reserve space for wallet id */}
            <div
                className="display-flex justify-content-between margin-top-3"
                style={{ height: '21px' }}
            >
                {walletId && (
                    <div className="text-left">
                        <Text
                            color="subdued"
                            className="text-gray-dark"
                            data-cmp="wallet-id"
                            size={200}
                        >
                            <Text weight="bold">My Wallet ID</Text>
                            :&nbsp;
                            {walletId}
                        </Text>
                    </div>
                )}
                {/* Add SyncWalletAlert back in when it has been converted to <Label /> */}
                {showAlert && (
                    <Label bsStyle="secondary">
                        <Glyphicon
                            className="margin-right-1"
                            glyph="success"
                        />
                        Settings imported!
                    </Label>
                )}
            </div>
        </div>
    );
}

export default SrpWalletGridInputs;
