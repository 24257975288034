import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';
import _isEqual from 'lodash/isEqual';

import {
    Badge,
    Button,
    Glyphicon,
    ItemCard,
    Panel,
} from 'reaxl';
import { InventoryDealerInfo } from 'reaxl-organisms';

// Change Contact Dealer
/**
 *  Dealer state Component for Alpha
 */
function OwnerAlphaListing({
    clickType,
    emailOwnerProps,
    listingsCount,
    onGetDirectionsClick = () => { },
    onImageClick,
    onTileClick = () => { },
    onViewOwnerInventoryClick = () => { },
    orientation = 'landscape',
    owner = {},
    parentId,
    phoneClickHandler = () => { },
    uiContext = 'owner',
    ...rest
}) {

    const rootClassName = clsx(rest.className, {
        'bg-periwinkle': true,
    });

    const showViewOwnerInventory = onViewOwnerInventoryClick instanceof Function;

    const renderDealerInventoryButton = () => {
        if (uiContext === 'ownerLite') {
            return (
                <>
                    <hr />
                    <Badge className="margin-right-2">
                        {listingsCount}
                    </Badge>
                    <Button
                        bsStyle="text"
                        className="margin-left-auto text-right margin-top-1"
                        onClick={(event) => onViewOwnerInventoryClick(event, owner)}
                    >
                        View all vehicles in stock
                    </Button>
                </>
            );
        }

        return (
            <Button
                onClick={(event) => onViewOwnerInventoryClick(event, owner)}
            >
                <Glyphicon
                    glyph="atcar"
                    className="margin-right-2"
                />
                View Cars
            </Button>
        );
    };

    const renderBody = () => (
        <InventoryDealerInfo
            phoneClickHandler={phoneClickHandler}
            emailOwnerProps={emailOwnerProps}
            lazyLoad={false} // set lazyload to false since alpha is at the top of the page
            owner={owner}
            orientation={orientation}
            onGetDirectionsClick={onGetDirectionsClick}
            onTileClick={onTileClick}
            parentId={parentId}
            uiContext={uiContext}
            clickType={clickType}
            {...rest}
        />
    );

    const renderFooter = () => showViewOwnerInventory && renderDealerInventoryButton();

    return (
        <Panel
            data-cmp="ownerAlphaListing"
            {...rest}
            className={rootClassName}
        >
            <Panel.Body style={{ height: '100%' }}>
                <ItemCard
                    orientation={orientation}
                    body={renderBody()}
                    footer={renderFooter()}
                    style={{ height: '100%' }}
                />
            </Panel.Body>
        </Panel>
    );
}

OwnerAlphaListing.propTypes = {
    /**
     * The className to apply to the root node
     */
    className: PropTypes.string,
    /**
     * Alpha owner properties
     */
    owner: PropTypes.object,
    /**
     * The emailOwnerProps Object has the props to pass through to the ConnectedEmailOwner component.
     */
    emailOwnerProps: PropTypes.object,
    /**
     * Handler for the Get Directions link
     */
    onGetDirectionsClick: PropTypes.func,
    /**
     * Handle for onTileClick
     */
    onTileClick: PropTypes.func,
    /**
     * Handler for the View Dealer Inventory Link
     */
    onViewOwnerInventoryClick: PropTypes.func,
    /**
     * uiContext to control display of view dealer inventory
     */
    orientation: PropTypes.oneOf([
        'portrait',
        'landscape',
    ]),
    /**
     * analytics parameter
     */
    parentId: PropTypes.string,
    /**
     * The callback function fires when owner phone is called
     */
    phoneClickHandler: PropTypes.func,
    /**
     * uiContext to control display of inventory listing side bar
     */
    uiContext: PropTypes.oneOf(['owner', 'ownerLite', 'inventory']),
};

export default React.memo(OwnerAlphaListing, (newProps, oldProps) => _isEqual(newProps, oldProps));
