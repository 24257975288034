import React, { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';

import {
    ColoredBackground,
    Heading,
    ListColumns,
    Panel,
    ProgressBar,
    Section,
    SeeMore,
    StarRating,
    Text,
} from 'reaxl';

import { queryDuck } from '@/ducks';

import YmmModelInfoFetcher from '@/fetchers/srp/YmmModelInfoFetcher';

function SafetyReferenceContainer() {
    const makeCode = _get(useSelector(queryDuck.selectors.getDuckState), 'makeCode', '');
    const modelCode = _get(useSelector(queryDuck.selectors.getDuckState), 'modelCode', '');
    const trimCode = _get(useSelector(queryDuck.selectors.getDuckState), 'trimCode', '');
    const startYear = _get(useSelector(queryDuck.selectors.getDuckState), 'startYear', '');

    const [safetyInfo, setSafetyInfo] = useState({});

    const modelRules = !!modelCode && typeof modelCode !== 'object';
    const makeRules = !!makeCode && typeof makeCode !== 'object';
    const displaySafetyReference = makeRules && modelRules && !trimCode && safetyInfo.length;

    const getSafetyInfo = useCallback(async () => {
        const currentYear = new Date().getFullYear();
        let queryYear = startYear;

        if (!startYear) {
            queryYear = currentYear - 1;
        }

        if (!!makeCode && !!modelCode) {
            const response = await YmmModelInfoFetcher({
                makeCode,
                modelCode,
                startYear: queryYear,
            });

            setSafetyInfo(response);
        }
    }, [makeCode, modelCode, startYear]);

    useEffect(() => {
        getSafetyInfo();
    }, [makeCode, modelCode, startYear, getSafetyInfo]);

    const safetyRatings = safetyInfo.length && safetyInfo[0].features['Safety Ratings'];
    const safetyFeatures = safetyInfo.length && safetyInfo[0].features['Safety Features'];

    const ratingName = (label) => {
        if (label) {
            switch (label) {
                case 'Driver Crash Grade':
                    return 'Driver Side';
                case 'Passenger Crash Grade':
                    return 'Passenger Side';
                case 'Side Impact Crash Front':
                    return 'Front Seats';
                case 'Side Impact Crash Rear':
                    return 'Rear Seats';
                case 'Rollover Resistance':
                    return 'Overall';
                default:
                    break;
            }
        }

        return '';
    };

    const barRatings = (label, value) => value !== '0' && (
        <>
            <div className="col-xs-5 progress-bar-consumer-rating">
                <Text className="margin-bottom-1">{ratingName(label)}</Text>
                <ProgressBar
                    key={label}
                    now={Number(value)}
                    max={5}
                    bsStyle="warning"
                    style={{ height: 10 }}
                />
            </div>

            <Text
                className="col-xs-1"
                weight="bold"
            >
                {Number(value).toFixed(1)}
            </Text>
        </>
    );

    const renderRatingSubsection = (subtitle, ratings) => (
        <>
            <Text weight="bold">{subtitle}</Text>
            <div className="row display-flex align-items-center margin-top-1 margin-bottom-2">
                {ratings.map(({ label, value }) => barRatings(label, value))}
            </div>
        </>
    );

    const renderBreakdown = () => {
        const frontalImpact = safetyRatings.filter((rating) => rating.label.includes('Crash Grade'));
        const sideImpact = safetyRatings.filter((rating) => rating.label.includes('Side Impact'));
        const rolloverResist = safetyRatings.filter((rating) => rating.label.includes('Rollover Resistance'));
        return (
            <div className="margin-top-4">
                {!!frontalImpact && renderRatingSubsection('Frontal Impact', frontalImpact)}
                {!!sideImpact && renderRatingSubsection('Side Impact Crash', sideImpact)}
                {!!rolloverResist && renderRatingSubsection('Rollover Resistance Rating', rolloverResist)}
            </div>
        );
    };

    const renderOverallRating = () => {
        const ratingsValues = safetyRatings.map((rating) => Number(rating.value));
        const availableRatingsValues = !!ratingsValues && ratingsValues.filter((value) => value !== 0);
        const ratingsAverage = !!availableRatingsValues && availableRatingsValues.reduce((start, next) => start + next) / availableRatingsValues.length;
        return (
            <div className="display-flex">
                <div className="text-size-700 text-bold margin-right-3">
                    {ratingsAverage.toFixed(1)}
                </div>
                <div>
                    <div>
                        <StarRating
                            value={ratingsAverage.toFixed(1)}
                            color="#e8bb38"
                            className="text-size-500 margin-top-1"
                        />
                    </div>
                    <div
                        className="text-size-200 text-bold"
                        style={{ marginTop: '-6px' }}
                    >
                        Overall Rating
                    </div>
                </div>
            </div>
        );
    };

    const renderRatings = !!safetyRatings && (
        <div className="col-xs-12 col-sm-6">
            <div>
                <Text
                    className="margin-bottom-2"
                    weight="bold"
                    size={400}
                >
                    Crash Test Ratings
                </Text>

                {!!safetyRatings && renderOverallRating()}
            </div>

            {renderBreakdown()}
        </div>
    );

    const standardFeatures = !!safetyFeatures && safetyFeatures.filter((feature) => feature.value === 'std');
    const featuresList = !!standardFeatures && standardFeatures.map((feature) => feature.label);

    const renderFeatures = !!safetyFeatures && (
        <div className="col-xs-12 col-sm-6 margin-top-4 margin-top-sm-0">
            <Panel className="padding-3">
                <Panel.Body>
                    <Heading
                        size={400}
                        className="margin-bottom-3"
                    >
                        Safety Features
                    </Heading>
                    {featuresList && (
                        <SeeMore>
                            <ListColumns
                                className="list-unstyled"
                                xs={2}
                                sm={3}
                                md={3}
                                lg={3}
                                items={featuresList}
                                expanded
                            />
                        </SeeMore>
                    )}
                </Panel.Body>
            </Panel>
        </div>
    );

    return displaySafetyReference ? (
        <ColoredBackground uiContext="default">
            <Section
                id="safety-reference-container"
                heading="Safety"
                alignment="left"
                uiContext="section-2"
                className="container margin-vertical-4 padding-vertical-4 text-normal"
            >
                <div className="row margin-top-3">
                    {renderRatings}
                    {renderFeatures}
                </div>
            </Section>
        </ColoredBackground>
    ) : null;
}

export default SafetyReferenceContainer;
