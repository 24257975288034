import { fetchJSON } from '@bonnet/next/fetch';

/*
* @description SearchOptions Reference Packages Filter fetcher
* Gets Reference Packages data using makeCode,modelCode and trimCode
*/
export default ({ makeCode, modelCode, trimCode }) => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
        },
        circuitBreaker: {
            id: 'referenceFilterPackages',
            timeout: 5000,
            resetTimeout: 300000,
            fallback: {
                error: 'Circuit Breaker for /searchoptions/packages is open',
            },
        },
    };

    const params = `makeCode=${makeCode}&modelCode=${modelCode}&trimCode=${trimCode}`;
    const endpoint = `/rest/v0/searchoptions/packages?${params}`;

    return fetchJSON(endpoint, options);
};
